// 注册自定义组件渲染器
import { registerRendererByType } from '@/utils/amis-widget/index.esm.js'
import Amap from './amap.vue'

registerRendererByType(Amap, {
  type: 'amap',
  usage: 'renderer',
  weight: 99,
  framework: 'vue'
})
