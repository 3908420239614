/* eslint-disable no-template-curly-in-string */
/**
 * @file 编辑器扩展, 增加自定义组件
 */
import { BasePlugin, registerEditorPlugin } from 'amis-editor'

export class InfoGridPlugin extends BasePlugin {
  // 关联渲染器名字
  rendererName = 'com-pie-chart';
  $schema = '/schemas/UnkownSchema.json';
  notRenderFormZone = true;
  // 组件名称（组件面板显示的Title）
  name = '活跃统计';
  order=0; // 排序
  description = '活跃统计展示信息';
  tags = ['布局容器', '驾驶舱'];
  icon = 'fa fa-file-code-o';
  scaffold = { // 插入到页面时需要
    'type': 'card',
    'header': {
      'title': '',
      'subTitle': ''
    },
    'body': [
      {
        'type': 'tabs',
        'tabs': [
          {
            'title': '今日活跃用户',
            'value': 1,
            'body': [
              {
                'type': 'chart',
                'config': {
                  'xAxis': {
                    'type': 'time',
                    'id': 'u:f4c5d3195bad',
                    'axisLabel': {
                      'formatter': '{yyyy}-{MM}-{dd} {HH}:{mm}:{ss}'
                    }
                  },
                  'yAxis': {
                    'type': 'value',
                    'id': 'u:8887928a6efc'
                  },
                  'series': [
                  ],
                  'backgroundColor': 'transparent',
                  'dataZoom': [
                    {
                      'start': 0,
                      'end': 10
                    }
                  ],
                  'tooltip': {
                    'trigger': 'axis'
                  }
                },
                'replaceChartOption': false,
                'id': 'u:104133c688da',
                'name': 'historyChart',
                'style': {
                  'boxShadow': ' 0px 0px 0px 0px transparent',
                  'width': '100%',
                  'height': '300px'
                },
                'api': {
                  'url': '/gather/home/index/activeUser',
                  'method': 'get',
                  'requestAdaptor': '',
                  'adaptor': '',
                  'messages': {
                  }
                },
                'initFetch': true,
                'dataFilter': "config.legend={\r\n  data:[]\r\n};\r\nconfig.series = data.items.map(function(item) { \r\n  config.legend.data.push(item.name);\r\n  let obj = { name: item.name, data:[],type:'line'}; \r\n  item.dps.forEach(function(itemDps) { \r\n    obj.data.push([formatDate(Number(itemDps.ts)), itemDps.val]) \r\n  }); \r\n  return obj;\r\n}); \r\n\r\nfunction formatDate(value) {var date = new Date(value);var y = date.getFullYear(),m = date.getMonth() + 1,d = date.getDate(),h = date.getHours(),i = date.getMinutes(),s = date.getSeconds();if (m < 10) { m = '0' + m; }if (d < 10) { d = '0' + d; }if (h < 10) { h = '0' + h; }if (i < 10) { i = '0' + i; }if (s < 10) { s = '0' + s; }var t = y + '-' + m + '-' + d + ' ' + h + ':' + i + ':' + s;return t;\r\n}\r\nconsole.log('config====',config);\r\n"
              }
            ],
            'id': 'u:125f4e100965',
            'className': 'h-80 p-b-sm'
          },
          {
            'title': '1个月活跃用户',
            'body': [
              {
                'type': 'chart',
                'config': {
                  'xAxis': {
                    'type': 'time',
                    'id': 'u:f4c5d3195bad',
                    'axisLabel': {
                      'formatter': '{yyyy}-{MM}-{dd} {HH}:{mm}:{ss}'
                    }
                  },
                  'yAxis': {
                    'type': 'value',
                    'id': 'u:8887928a6efc'
                  },
                  'series': [
                  ],
                  'backgroundColor': 'transparent',
                  'dataZoom': [
                    {
                      'start': 0,
                      'end': 10
                    }
                  ],
                  'tooltip': {
                    'trigger': 'axis'
                  }
                },
                'replaceChartOption': false,
                'id': 'u:104133c688db',
                'name': 'historyChart',
                'style': {
                  'boxShadow': ' 0px 0px 0px 0px transparent',
                  'width': '100%',
                  'height': '300px'
                },
                'api': {
                  'url': '/gather/home/index/activeUser',
                  'method': 'get',
                  'requestAdaptor': '',
                  'adaptor': '',
                  'messages': {
                  }
                },
                'initFetch': false,
                'dataFilter': "config.legend={\r\n  data:[]\r\n};\r\nconfig.series = data.items.map(function(item) { \r\n  config.legend.data.push(item.name);\r\n  let obj = { name: item.name, data:[],type:'line'}; \r\n  item.dps.forEach(function(itemDps) { \r\n    obj.data.push([formatDate(Number(itemDps.ts)), itemDps.val]) \r\n  }); \r\n  return obj;\r\n}); \r\n\r\nfunction formatDate(value) {var date = new Date(value);var y = date.getFullYear(),m = date.getMonth() + 1,d = date.getDate(),h = date.getHours(),i = date.getMinutes(),s = date.getSeconds();if (m < 10) { m = '0' + m; }if (d < 10) { d = '0' + d; }if (h < 10) { h = '0' + h; }if (i < 10) { i = '0' + i; }if (s < 10) { s = '0' + s; }var t = y + '-' + m + '-' + d + ' ' + h + ':' + i + ':' + s;return t;\r\n}\r\nconsole.log('config====',config);\r\n"
              }
            ],
            'id': 'u:de04915b0af7',
            'value': 2
          },
          {
            'title': '6个月活跃用户',
            'body': [
              {
                'type': 'chart',
                'config': {
                  'xAxis': {
                    'type': 'time',
                    'id': 'u:f4c5d3195bad',
                    'axisLabel': {
                      'formatter': '{yyyy}-{MM}-{dd} {HH}:{mm}:{ss}'
                    }
                  },
                  'yAxis': {
                    'type': 'value',
                    'id': 'u:8887928a6efc'
                  },
                  'series': [
                  ],
                  'backgroundColor': 'transparent',
                  'dataZoom': [
                    {
                      'start': 0,
                      'end': 10
                    }
                  ],
                  'tooltip': {
                    'trigger': 'axis'
                  }
                },
                'replaceChartOption': false,
                'id': 'u:104133c688dc',
                'name': 'historyChart',
                'style': {
                  'boxShadow': ' 0px 0px 0px 0px transparent',
                  'width': '100%',
                  'height': '300px'
                },
                'api': {
                  'url': '/gather/home/index/activeUser',
                  'method': 'get',
                  'requestAdaptor': '',
                  'adaptor': '',
                  'messages': {
                  }
                },
                'initFetch': false,
                'dataFilter': "config.legend={\r\n  data:[]\r\n};\r\nconfig.series = data.items.map(function(item) { \r\n  config.legend.data.push(item.name);\r\n  let obj = { name: item.name, data:[],type:'line'}; \r\n  item.dps.forEach(function(itemDps) { \r\n    obj.data.push([formatDate(Number(itemDps.ts)), itemDps.val]) \r\n  }); \r\n  return obj;\r\n}); \r\n\r\nfunction formatDate(value) {var date = new Date(value);var y = date.getFullYear(),m = date.getMonth() + 1,d = date.getDate(),h = date.getHours(),i = date.getMinutes(),s = date.getSeconds();if (m < 10) { m = '0' + m; }if (d < 10) { d = '0' + d; }if (h < 10) { h = '0' + h; }if (i < 10) { i = '0' + i; }if (s < 10) { s = '0' + s; }var t = y + '-' + m + '-' + d + ' ' + h + ':' + i + ':' + s;return t;\r\n}\r\nconsole.log('config====',config);\r\n"
              }
            ],
            'id': 'u:47fd6fa794ae',
            'value': 3
          }
        ],
        'id': 'u:429cbe19acd2',
        'label': '',
        'toolbar': [
          {
            'type': 'input-datetime-range',
            'label': '',
            'name': 'startTime',
            'id': 'u:39be301fe0f4',
            'inputFormat': 'YYYY-MM-DD HH:mm:ss',
            'timeFormat': 'HH:mm:ss',
            'placeholder': '请选择日期时间范围',
            'format': 'YYYY-MM-DD HH:mm:ss',
            'minDate': '',
            'maxDate': '',
            'value': '',
            'shortcuts': [
              'yesterday',
              '7daysago',
              'prevweek',
              'thismonth',
              'prevmonth',
              'prevquarter'
            ],
            'extraName': 'endTime',
            'className': 'm-b-sm'
          }
        ],
        'onEvent': {
          'change': {
            'weight': 0,
            'actions': [
              {
                'componentId': 'u:104133c688da',
                'ignoreError': false,
                'actionType': 'reload'
              },
              {
                'componentId': 'u:104133c688db',
                'ignoreError': false,
                'actionType': 'reload'
              },
              {
                'componentId': 'u:104133c688dc',
                'ignoreError': false,
                'actionType': 'reload'
              }
            ]
          }
        },
        'toolbarClassName': 'p-b-sm',
        'mountOnEnter': false,
        'unmountOnExit': false,
        'activeKey': '',
        'defaultKey': ''
      }
    ],
    'actions': [
    ],
    'bodyClassName': 'p-b-sm'
  };
  previewSchema = { // 组件面板预览时需要
    'type': 'card',
    'header': {
      'title': '',
      'subTitle': ''
    },
    'body': [
      {
        'type': 'tabs',
        'tabs': [
          {
            'title': '今日活跃用户',
            'value': 1,
            'body': [
              {
                'type': 'chart',
                'config': {
                  'xAxis': {
                    'type': 'time',
                    'id': 'u:f4c5d3195bad',
                    'axisLabel': {
                      'formatter': '{yyyy}-{MM}-{dd} {HH}:{mm}:{ss}'
                    }
                  },
                  'yAxis': {
                    'type': 'value',
                    'id': 'u:8887928a6efc'
                  },
                  'series': [
                  ],
                  'backgroundColor': 'transparent',
                  'dataZoom': [
                    {
                      'start': 0,
                      'end': 10
                    }
                  ],
                  'tooltip': {
                    'trigger': 'axis'
                  }
                },
                'replaceChartOption': false,
                'id': 'u:104133c688da',
                'name': 'historyChart',
                'style': {
                  'boxShadow': ' 0px 0px 0px 0px transparent',
                  'width': '100%',
                  'height': '300px'
                },
                'api': {
                  'url': '/gather/home/index/activeUser',
                  'method': 'get',
                  'requestAdaptor': '',
                  'adaptor': '',
                  'messages': {
                  }
                },
                'initFetch': true,
                'dataFilter': "config.legend={\r\n  data:[]\r\n};\r\nconfig.series = data.items.map(function(item) { \r\n  config.legend.data.push(item.name);\r\n  let obj = { name: item.name, data:[],type:'line'}; \r\n  item.dps.forEach(function(itemDps) { \r\n    obj.data.push([formatDate(Number(itemDps.ts)), itemDps.val]) \r\n  }); \r\n  return obj;\r\n}); \r\n\r\nfunction formatDate(value) {var date = new Date(value);var y = date.getFullYear(),m = date.getMonth() + 1,d = date.getDate(),h = date.getHours(),i = date.getMinutes(),s = date.getSeconds();if (m < 10) { m = '0' + m; }if (d < 10) { d = '0' + d; }if (h < 10) { h = '0' + h; }if (i < 10) { i = '0' + i; }if (s < 10) { s = '0' + s; }var t = y + '-' + m + '-' + d + ' ' + h + ':' + i + ':' + s;return t;\r\n}\r\nconsole.log('config====',config);\r\n"
              }
            ],
            'id': 'u:125f4e100965',
            'className': 'h-80 p-b-sm'
          },
          {
            'title': '1个月活跃用户',
            'body': [
              {
                'type': 'chart',
                'config': {
                  'xAxis': {
                    'type': 'time',
                    'id': 'u:f4c5d3195bad',
                    'axisLabel': {
                      'formatter': '{yyyy}-{MM}-{dd} {HH}:{mm}:{ss}'
                    }
                  },
                  'yAxis': {
                    'type': 'value',
                    'id': 'u:8887928a6efc'
                  },
                  'series': [
                  ],
                  'backgroundColor': 'transparent',
                  'dataZoom': [
                    {
                      'start': 0,
                      'end': 10
                    }
                  ],
                  'tooltip': {
                    'trigger': 'axis'
                  }
                },
                'replaceChartOption': false,
                'id': 'u:104133c688db',
                'name': 'historyChart',
                'style': {
                  'boxShadow': ' 0px 0px 0px 0px transparent',
                  'width': '100%',
                  'height': '300px'
                },
                'api': {
                  'url': '/gather/home/index/activeUser',
                  'method': 'get',
                  'requestAdaptor': '',
                  'adaptor': '',
                  'messages': {
                  }
                },
                'initFetch': false,
                'dataFilter': "config.legend={\r\n  data:[]\r\n};\r\nconfig.series = data.items.map(function(item) { \r\n  config.legend.data.push(item.name);\r\n  let obj = { name: item.name, data:[],type:'line'}; \r\n  item.dps.forEach(function(itemDps) { \r\n    obj.data.push([formatDate(Number(itemDps.ts)), itemDps.val]) \r\n  }); \r\n  return obj;\r\n}); \r\n\r\nfunction formatDate(value) {var date = new Date(value);var y = date.getFullYear(),m = date.getMonth() + 1,d = date.getDate(),h = date.getHours(),i = date.getMinutes(),s = date.getSeconds();if (m < 10) { m = '0' + m; }if (d < 10) { d = '0' + d; }if (h < 10) { h = '0' + h; }if (i < 10) { i = '0' + i; }if (s < 10) { s = '0' + s; }var t = y + '-' + m + '-' + d + ' ' + h + ':' + i + ':' + s;return t;\r\n}\r\nconsole.log('config====',config);\r\n"
              }
            ],
            'id': 'u:de04915b0af7',
            'value': 2
          },
          {
            'title': '6个月活跃用户',
            'body': [
              {
                'type': 'chart',
                'config': {
                  'xAxis': {
                    'type': 'time',
                    'id': 'u:f4c5d3195bad',
                    'axisLabel': {
                      'formatter': '{yyyy}-{MM}-{dd} {HH}:{mm}:{ss}'
                    }
                  },
                  'yAxis': {
                    'type': 'value',
                    'id': 'u:8887928a6efc'
                  },
                  'series': [
                  ],
                  'backgroundColor': 'transparent',
                  'dataZoom': [
                    {
                      'start': 0,
                      'end': 10
                    }
                  ],
                  'tooltip': {
                    'trigger': 'axis'
                  }
                },
                'replaceChartOption': false,
                'id': 'u:104133c688dc',
                'name': 'historyChart',
                'style': {
                  'boxShadow': ' 0px 0px 0px 0px transparent',
                  'width': '100%',
                  'height': '300px'
                },
                'api': {
                  'url': '/gather/home/index/activeUser',
                  'method': 'get',
                  'requestAdaptor': '',
                  'adaptor': '',
                  'messages': {
                  }
                },
                'initFetch': false,
                'dataFilter': "config.legend={\r\n  data:[]\r\n};\r\nconfig.series = data.items.map(function(item) { \r\n  config.legend.data.push(item.name);\r\n  let obj = { name: item.name, data:[],type:'line'}; \r\n  item.dps.forEach(function(itemDps) { \r\n    obj.data.push([formatDate(Number(itemDps.ts)), itemDps.val]) \r\n  }); \r\n  return obj;\r\n}); \r\n\r\nfunction formatDate(value) {var date = new Date(value);var y = date.getFullYear(),m = date.getMonth() + 1,d = date.getDate(),h = date.getHours(),i = date.getMinutes(),s = date.getSeconds();if (m < 10) { m = '0' + m; }if (d < 10) { d = '0' + d; }if (h < 10) { h = '0' + h; }if (i < 10) { i = '0' + i; }if (s < 10) { s = '0' + s; }var t = y + '-' + m + '-' + d + ' ' + h + ':' + i + ':' + s;return t;\r\n}\r\nconsole.log('config====',config);\r\n"
              }
            ],
            'id': 'u:47fd6fa794ae',
            'value': 3
          }
        ],
        'id': 'u:429cbe19acd2',
        'label': '',
        'toolbar': [
          {
            'type': 'input-datetime-range',
            'label': '',
            'name': 'startTime',
            'id': 'u:39be301fe0f4',
            'inputFormat': 'YYYY-MM-DD HH:mm:ss',
            'timeFormat': 'HH:mm:ss',
            'placeholder': '请选择日期时间范围',
            'format': 'YYYY-MM-DD HH:mm:ss',
            'minDate': '',
            'maxDate': '',
            'value': '',
            'shortcuts': [
              'yesterday',
              '7daysago',
              'prevweek',
              'thismonth',
              'prevmonth',
              'prevquarter'
            ],
            'extraName': 'endTime',
            'className': 'm-b-sm'
          }
        ],
        'onEvent': {
          'change': {
            'weight': 0,
            'actions': [
              {
                'componentId': 'u:104133c688da',
                'ignoreError': false,
                'actionType': 'reload'
              },
              {
                'componentId': 'u:104133c688db',
                'ignoreError': false,
                'actionType': 'reload'
              },
              {
                'componentId': 'u:104133c688dc',
                'ignoreError': false,
                'actionType': 'reload'
              }
            ]
          }
        },
        'toolbarClassName': 'p-b-sm',
        'mountOnEnter': false,
        'unmountOnExit': false,
        'activeKey': '',
        'defaultKey': ''
      }
    ],
    'actions': [
    ],
    'bodyClassName': 'p-b-sm'
  };

  // 容器类组件必需字段
  // regions = [
  //   {
  //     key: 'body',
  //     label: '内容区'
  //   }
  // ];

  panelTitle = '配置'; // 右侧属性面板Title

  panelControls = [ // 右侧属性面板配置项
    // {
    //   type: 'tabs',
    //   tabsMode: 'line',
    //   className: 'editor-prop-config-tabs',
    //   contentClassName: 'no-border editor-prop-config-tabs-cont hoverShowScrollBar',
    //   linksClassName: 'editor-prop-config-tabs-links aa',
    //   tabs: [
    //     {
    //       title: '常规',
    //       className: 'p-none',
    //       controls: [
    //         {
    //           'type': 'collapse-group',
    //           className: 'ae-formItemControl ae-styleControl',
    //           'activeKey': [
    //             'grid_11', 'grid_12'
    //           ],
    //           body: [
    //             {
    //               'type': 'collapse',
    //               'key': 'grid_11',
    //               'header': '基本',
    //               headingClassName: 'ae-formItemControl-header',
    //               bodyClassName: 'ae-formItemControl-body',
    //               'body': [
    //                 {
    //                   type: 'input-text',
    //                   name: 'source_data',
    //                   label: '数据源',
    //                   value: 'deviceData',
    //                   // 'mode': 'inline',
    //                   'required': true

    //                 },
    //                 {
    //                   type: 'input-text',
    //                   name: 'grid_name',
    //                   label: '子设备名称',
    //                   value: 'deviceName',
    //                   // 'mode': 'inline',
    //                   'required': true
    //                 }
    //               ]
    //             },
    //             {
    //               'type': 'collapse',
    //               'key': 'grid_12',
    //               'header': '采集项',
    //               headingClassName: 'ae-formItemControl-header',
    //               bodyClassName: 'ae-formItemControl-body',
    //               'body': [
    //                 {
    //                   type: 'input-text',
    //                   name: 'grid_child_data',
    //                   label: '采集项数据',
    //                   value: 'data',
    //                   'required': true

    //                 },
    //                 {
    //                   type: 'input-text',
    //                   name: 'grid_child_name',
    //                   label: '名称',
    //                   value: 'attrName',
    //                   // 'mode': 'inline',
    //                   'required': true
    //                 },
    //                 {
    //                   type: 'input-text',
    //                   name: 'grid_child_value',
    //                   label: '数值',
    //                   value: 'value',
    //                   // 'mode': 'inline',
    //                   'required': true
    //                 },
    //                 {
    //                   type: 'input-text',
    //                   name: 'grid_child_unit',
    //                   label: '单位',
    //                   value: 'attrUnit'
    //                   // 'mode': 'inline'
    //                 },
    //                 {
    //                   type: 'input-text',
    //                   name: 'grid_child_icon',
    //                   label: '图标',
    //                   value: 'icon'
    //                   // 'mode': 'inline'
    //                 }
    //               ]
    //             }
    //           ]
    //         }

    //       ]
    //     },
    //     {
    //       title: '外观',
    //       className: 'p-none',
    //       controls: [
    //         {
    //           'type': 'collapse-group',
    //           className: 'ae-formItemControl ae-styleControl',
    //           'activeKey': [
    //             'grid_21', 'grid_22', 'grid_23'
    //           ],
    //           'body': [
    //             {
    //               'type': 'collapse',
    //               'key': 'grid_21',
    //               'header': '行数量',
    //               headingClassName: 'ae-formItemControl-header',
    //               bodyClassName: 'ae-formItemControl-body',
    //               'body': [
    //                 {
    //                   type: 'input-number',
    //                   name: 'grid_num',
    //                   label: '一行显示数量',
    //                   value: 5
    //                   // 'mode': 'inline'
    //                 }
    //               ]
    //             },
    //             {
    //               'type': 'collapse',
    //               'key': 'grid_22',
    //               'header': '显隐/排序设置',
    //               headingClassName: 'ae-formItemControl-header',
    //               bodyClassName: 'ae-formItemControl-body',
    //               'body': [
    //                 {
    //                   'type': 'switch',
    //                   'label': '状态',
    //                   'option': '',
    //                   'name': 'grid_edit',
    //                   'falseValue': false,
    //                   'trueValue': true,
    //                   'value': true,
    //                   'onText': '启用',
    //                   'offText': '禁用'
    //                 },
    //                 {
    //                   'type': 'input-text',
    //                   'label': '显隐key',
    //                   'name': 'grid_check_key',
    //                   'value': 'attrId',
    //                   'hiddenOn': '${AND(!grid_edit)}'
    //                 }
    //               ]
    //             },
    //             {
    //               'type': 'collapse',
    //               'key': 'grid_23',
    //               'header': '间距',
    //               headingClassName: 'ae-formItemControl-header',
    //               bodyClassName: 'ae-formItemControl-body',
    //               'body': [
    //                 {
    //                   type: 'input-number',
    //                   name: 'grid_margin_bottom',
    //                   label: '行间距',
    //                   value: 5
    //                   // 'mode': 'inline'
    //                 },
    //                 {
    //                   type: 'input-number',
    //                   name: 'grid_padding_top',
    //                   label: '上边距',
    //                   value: 10
    //                   // 'mode': 'inline'
    //                 },
    //                 {
    //                   type: 'input-number',
    //                   name: 'grid_padding_left',
    //                   label: '左边距',
    //                   value: 0
    //                   // 'mode': 'inline'
    //                 },
    //                 {
    //                   type: 'input-number',
    //                   name: 'grid_padding_bottom',
    //                   label: '下边距',
    //                   value: 10
    //                   // 'mode': 'inline'
    //                 },
    //                 {
    //                   type: 'input-number',
    //                   name: 'grid_padding_right',
    //                   label: '右边距',
    //                   value: 0
    //                   // 'mode': 'inline'
    //                 }
    //               ]
    //             },
    //             {
    //               'type': 'collapse',
    //               'key': 'grid_23',
    //               'header': '颜色',
    //               headingClassName: 'ae-formItemControl-header',
    //               bodyClassName: 'ae-formItemControl-body',
    //               'body': [
    //                 {
    //                   'type': 'input-color',
    //                   'label': '背景色',
    //                   'name': 'grid_background',
    //                   'format': 'rgba',
    //                   'value': 'rgba(255, 255, 255, 0)',
    //                   'labelRemark': {
    //                     'icon': 'fa fa-question-circle',
    //                     'trigger': [
    //                       'hover'
    //                     ],
    //                     'className': 'Remark--warning',
    //                     'placement': 'top',
    //                     'content': '设置整个区域的背景色'
    //                   },
    //                   'allowCustomColor': true
    //                   // 'mode': 'inline'
    //                 },
    //                 {
    //                   'type': 'input-color',
    //                   'label': '名称颜色',
    //                   'name': 'grid_name_color',
    //                   'format': 'rgba',
    //                   'value': 'rgb(87, 93, 108, 1)',
    //                   'labelRemark': {
    //                     'icon': 'fa fa-question-circle',
    //                     'trigger': [
    //                       'hover'
    //                     ],
    //                     'className': 'Remark--warning',
    //                     'placement': 'top',
    //                     'content': '设置名称颜色'
    //                   },
    //                   'resetValue': 'rgb(87, 93, 108, 1)',
    //                   'allowCustomColor': true
    //                   // 'mode': 'inline'
    //                 },
    //                 {
    //                   'type': 'input-color',
    //                   'label': '数值颜色',
    //                   'name': 'grid_value_color',
    //                   'format': 'rgba',
    //                   'value': 'rgb(28, 31, 39, 1)',
    //                   'labelRemark': {
    //                     'icon': 'fa fa-question-circle',
    //                     'trigger': [
    //                       'hover'
    //                     ],
    //                     'className': 'Remark--warning',
    //                     'placement': 'top',
    //                     'content': '设置数值颜色'
    //                   },
    //                   'resetValue': 'rgb(28, 31, 39, 1)',
    //                   'allowCustomColor': true
    //                   // 'mode': 'inline'
    //                 }
    //               ]
    //             }
    //           ]
    //         }
    //       ]
    //     },
    //     {
    //       title: '阈值',
    //       className: 'p-none',
    //       controls: [
    //         {
    //           'type': 'collapse-group',
    //           className: 'ae-formItemControl ae-styleControl',
    //           'activeKey': [
    //             'grid_31', 'grid_32'
    //           ],
    //           'body': [
    //             {
    //               'type': 'collapse',
    //               'key': 'grid_31',
    //               'header': '阈值数据设置',
    //               headingClassName: 'ae-formItemControl-header',
    //               bodyClassName: 'ae-formItemControl-body',
    //               'body': [
    //                 {
    //                   type: 'input-text',
    //                   name: 'grid_alarm_data',
    //                   label: '阈值数据',
    //                   value: 'threshold'
    //                   // 'mode': 'inline'
    //                 }
    //               ]
    //             },
    //             {
    //               'type': 'collapse',
    //               'key': 'grid_32',
    //               'header': '阈值显示设置',
    //               headingClassName: 'ae-formItemControl-header',
    //               bodyClassName: 'ae-formItemControl-body',
    //               'body': [
    //                 {
    //                   type: 'input-text',
    //                   name: 'grid_alarm_max',
    //                   label: '对应最大值',
    //                   value: 'max'
    //                   // 'mode': 'inline'
    //                 },
    //                 {
    //                   type: 'input-text',
    //                   name: 'grid_alarm_min',
    //                   label: '对应最小值',
    //                   value: 'min'
    //                   // 'mode': 'inline'
    //                 },
    //                 {
    //                   'type': 'input-text',
    //                   'label': '对应显示颜色',
    //                   'name': 'grid_alarm_color',
    //                   value: 'color'
    //                 }
    //               ]
    //             }
    //           ]
    //         }
    //       ]
    //     }
    //   ]

    // }

  ];
}

registerEditorPlugin(InfoGridPlugin)
