<template>
  <div class="grid">
    <div class="grid-box" :style="{'padding':isHasKey(grid_padding_top,0)+'px '+isHasKey(grid_padding_left,0)+'px '+isHasKey(grid_padding_bottom,0)+'px '+isHasKey(grid_padding_right,0)+'px','background':grid_background}" v-for="(itemP,indexP) in gridData" :key="'grid_p_'+indexP">
      <!-- 标题 start -->
      <div class="grid-box-name" :style="{'height':grid_title_height?grid_title_height+'px':'auto','paddingTop':isHasKey(grid_title_padding_top,0)+'px','paddingLeft':isHasKey(grid_padding_left,0)+'px','paddingBottom':isHasKey(grid_title_padding_bottom,0)+'px','paddingRight':isHasKey(grid_padding_right,0)+'px','marginRight':'-'+isHasKey(grid_padding_right,0)+'px','marginLeft':'-'+isHasKey(grid_padding_left,0)+'px','background':isHasKey(grid_title_background,'transparent'),'color':isHasKey(grid_title_color,'#333')}">
        <span>{{ itemP[grid_name] }}</span>
        <!-- 控制显隐、排序 -->
        <div v-if="grid_edit&&itemP[grid_child_data]&&itemP[grid_child_data].length>=0" class="grid-control">
          <a-dropdown :overlayStyle="{'zIndex':9999999}" >
            <a-menu slot="overlay">
              <a-tree
                checkable
                defaultExpandAll
                class="draggable-tree"
                draggable
                v-model="itemP.checkedKeys"
                :dropdownStyle="{'zIndex':9999999}"
                @dragenter="onDragEnter"
                @drop="onDrop($event,itemP,indexP)"
                @check="onCheck($event,itemP,indexP)"
              >
                <!-- @drop="onDrop($event,itemP)"
                  @check="onCheck($event,itemP)" -->
                <span slot="switcherIcon" class="iconClass" style="width: 100%;height: 100%;background-color: red;" @click.prevent="()=>{}"></span>
                <!-- <icon

                    :style="'height:0px'"
                    type=""
                  /> -->
                <a-tree-node halfChecked key="all" title="全选/不选">
                  <a-tree-node style="margin-left: -18px;" class="nodeClass" v-for="node in itemP[grid_child_data]" :key="itemP.id+'_' +node[grid_check_key]" :title="node[grid_child_name]">

                  </a-tree-node>
                </a-tree-node>

              </a-tree>
            </a-menu>
            <a-button style="margin-right: 5px;" @click="e => e.preventDefault()">
              <a-icon type="appstore" />
              <a-icon type="down" style="margin-left: 8px"/>
            </a-button>
          </a-dropdown>

          <a-tooltip
            :overlayStyle="{'zIndex':9999999}"
          >
            <div slot="title">
              点击选择显示数据项，调整数据项顺序
            </div>
            <a-icon type="question-circle" />
          </a-tooltip>

        </div>
      </div>
      <!-- 标题 end -->
      <div class="grid-box-data">
        <div class="grid-box-item" :class="itemP.checkedKeys&&grid_check_key&&!!item[grid_check_key]&&!itemP.checkedKeys.includes(itemP.id+'_'+item[grid_check_key])?'grid_item_hide':''" :style="{'width':'calc('+(100/grid_num)+'% - 10px)','background':item[grid_child_icon]&&item[grid_child_icon]!=null?'url('+item[grid_child_icon]+')  no-repeat':'url('+require('./img/1.png')+')  no-repeat','background-size': '100% 100%','marginBottom':index<(itemP[grid_child_data].length-itemP[grid_child_data].length%grid_num)?grid_margin_bottom+'px':0}" v-for="(item,index) in itemP[grid_child_data]" :key="'grid_'+index">
          <!-- <div class="grid-box-item-icon">
            <img style="height: 100%;" :src="item[grid_child_icon]||require('./img/icon.png')" >
          </div> -->
          <div class="grid-box-item-info">
            <span class="grid-box-item-info-num" :style="{'color':fontColorHandle(item,grid_value_color)}">{{ item[grid_child_value]||'--' }}{{ item[grid_child_unit]||'℃' }}</span>
            <span class="grid-box-item-info-name" :style="{'color':fontColorHandle(item,grid_name_color)}">{{ item[grid_child_name]||'默认名称' }}{{ item[grid_child_icon] }}</span>
            <!-- <span class="grid-box-item-info-num" :style="{'color':fontColorHandle(item,grid_value_color)}">{{ grid_unit }}</span> -->
          </div>
        </div>
      </div>

    </div>
  </div>
</template>
<script>

export default {
  props: {
    // // 图标key
    // gridIcon: {
    //   type: String,
    //   default: 'icon'
    // },
    // // 名称key
    // gridName: {
    //   type: String,
    //   default: 'name'
    // },
    // // 数据key
    // gridValue: {
    //   type: String,
    //   default: 'value'
    // },
    // // 单位key
    // gridUnit: {
    //   type: String,
    //   default: 'unit'
    // },
    // // 数据源
    // sourceData: {
    //   type: String,
    //   default: 'gridData'
    // },
    // // 一行显示数量
    // gridNum: {
    //   type: Number,
    //   default: 5
    // },
    // // 行间距
    // gridMarginBottom: {
    //   type: Number,
    //   default: 5
    // },
    // // 上边距
    // gridPaddingTop: {
    //   type: Number,
    //   default: 10
    // },
    // // 左边距
    // gridPaddingLeft: {
    //   type: Number,
    //   default: 0
    // },
    // // 下边距
    // gridPaddingBottom: {
    //   type: Number,
    //   default: 10
    // },
    // // 右边距
    // gridPaddingRight: {
    //   type: Number,
    //   default: 0
    // },
    // // 背景色
    // gridBackground: {
    //   type: String,
    //   default: 'transparent'
    // },
    // // 名称颜色
    // gridNameColor: {
    //   type: String,
    //   default: '#575d6c'
    // },
    // // 数值颜色
    // gridValueColor: {
    //   type: String,
    //   default: '#1c1f27'
    // },
    // // 报警上限设置
    // // 最大值
    // gridAlarmMaxNum: {
    //   type: String,
    //   default: 'maxNum'
    // },
    // // 字体颜色
    // gridAlarmMaxColor: {
    //   type: String,
    //   default: '#1c1f27'
    // },
    // // 报警下限设置
    // // 最小值
    // gridAlarmMinNum: {
    //   type: String,
    //   default: 'minNum'
    // },
    // // 字体颜色
    // gridAlarmMinColor: {
    //   type: String,
    //   default: '#1c1f27'
    // },
    // // 是否显示控制编辑按钮
    // gridEdit: {
    //   type: Boolean,
    //   default: true
    // },
    // // 显隐key
    // gridCheckKey: {
    //   type: String,
    //   default: 'id'
    // },
    // renderChild: Function
  },
  data() {
    return {
      isAlive: true,
      isFirstVisit: true,
      // treeData: [],
      checkedKeys: [],
      dragList: [],
      checkedKeysLocal: [], // 本地存储的显示的数据id
      pageId: null, // 页面id
      visible: false
      // gridData: []
      // girdData: [
      //   {
      //     name: '温度',
      //     value: 50,
      //     unit: '℃',
      //     maxValue: 100,
      //     minValue: 10
      //   },
      //   {
      //     name: '湿度',
      //     value: 50,
      //     unit: '%',
      //     maxValue: 100,
      //     minValue: 10
      //   },
      //   {
      //     name: 'PM2.5',
      //     value: 50,
      //     unit: '',
      //     maxValue: 100,
      //     minValue: 10
      //   },
      //   {
      //     name: 'PM10',
      //     value: 50,
      //     unit: '',
      //     maxValue: 100,
      //     minValue: 10
      //   }
      // ]
    }
  },
  computed: {
    gridData() {
      // this.treeData = this.$data.data[this.source_data] || []
      // this.treeData = [
      //   {
      //     projectName: '全选/不选',
      //     id: '1',
      //     children: treeData
      //   }
      // ]
      console.log('dragList>>>>2>>>>', this.dragList)
      console.log('checkedKeys>>>>2>>>>', this.checkedKeys)
      console.log('source_data>>>>2>>>>', this.source_data)
      console.log('source_data>>>>2>>>>', this.$data.data[this.source_data])
      const iotPassGridShow = localStorage.getItem('iot_pass_grid_show') // 获取本地缓存的显示数据
      const iotPassDrag = localStorage.getItem('iot_pass_drag') // 获取本地缓存的排序数据
      if ((this.$data.data[this.source_data] && this.$data.data[this.source_data].length > 0)) {
        const data = []
        this.$data.data[this.source_data].forEach((itemD) => {
          const item = JSON.parse(JSON.stringify(itemD))
          item['visible'] = false

          if (iotPassGridShow) {
            item['checkedKeys'] = JSON.parse(iotPassGridShow)[this.pageId + '_' + item.id]
            // const dragList = JSON.parse(iotPassDrag)['11'] || []
            console.log('checkedKeys>>>>', item['checkedKeys'])
          }

          // 获取排序下标
          if (iotPassDrag) {
            item['dragList'] = JSON.parse(iotPassDrag)[this.pageId + '_' + item.id] || []
          } else {
            item['dragList'] = []
          }

          let isSetCheckdKes = false // 是否需要重置
          if ((!item['checkedKeys'] || item['checkedKeys'] === undefined) && item && item[this.grid_child_data].length > 0) {
            isSetCheckdKes = true
            item['checkedKeys'] = []
          }

          const childData = JSON.parse(JSON.stringify(item[this.grid_child_data]))
          item[this.grid_child_data] = []
          childData.forEach(itemC => {
            if (isSetCheckdKes) {
              console.log('itemC[this.grid_check_key]>>>>', itemC[this.grid_check_key])

              item['checkedKeys'].push(item.id + '_' + itemC[this.grid_check_key])
            }
            // 获取排序下标
            const idIndex = item['dragList'].indexOf(itemC[this.grid_check_key])
            if (idIndex === -1) {
              // 如果当前元素没有在排序数组中，直接添加到后面
              item[this.grid_child_data].push(itemC)
            } else {
              // 如果有排序，添加到对应下标下
              item[this.grid_child_data][idIndex] = itemC
            }
          })
          if (!item.isSelf || item.data.length > 0) {
            data.push(item)
          }
        })
        console.log('data>>>>data>>>>>', data)

        return data
      } else {
        return this.$data.data[this.source_data]
      }
    }
    // // 获取排序数组
    // dragList() {
    //   const iotPassDrag = localStorage.getItem('iot_pass_drag')
    //   let list = []
    //   if (iotPassDrag) {
    //     list = JSON.parse(iotPassDrag)['11'] || []
    //   }
    //   return list
    // }
  },
  mounted() {
    this.isFirstVisit = false
    // 获取排序数组
    const iotPassDrag = localStorage.getItem('iot_pass_drag')
    console.log('iotPassDrag>>>>', iotPassDrag)
    console.log('mounted>>>>this>>>>>', this)
    console.log('mounted>>>>this>>>>>pageId>>>>', this.id)
    this.pageId = this.id
    // this.dragList = []
    // if (iotPassDrag) {
    //   this.dragList = JSON.parse(iotPassDrag)[this.pageId] || []
    //   // const dragList = JSON.parse(iotPassDrag)['11'] || []
    //   console.log('dragList>>>>', this.dragList)
    // }

    // const iotPassGridShow = localStorage.getItem('iot_pass_grid_show')
    // console.log('iotPassGridShow>>>>', iotPassGridShow)

    // this.checkedKeys = []
    // if (iotPassGridShow) {
    //   this.checkedKeys = JSON.parse(iotPassGridShow)[this.pageId]
    //   // const dragList = JSON.parse(iotPassDrag)['11'] || []
    //   console.log('checkedKeys>>>>', this.checkedKeys)
    // }
    // console.log('>>>>>>6>>>>this>>>>>', this)
    // console.log('mounted>>>>>>6>>>>', this.$data.data[this.source_data])
  },
  updated() {
    if (this.renderChild && this.body) {
      this.renderChild('body', this.body, 'ReactChildBody')
    }
    // console.log('updated>>>>>', this.body)
    // console.log('>>>>>>5>>>>', this.source_data)
    console.log('>>>>>>6>>>>updated>>>>', this)
    console.log('>>>>>>6>>>>', this.$data.data)
    console.log('>>>>>>6>>>>', this.$data.data[this.source_data])
    if (!this.checkedKeys && this.$data.data[this.source_data] && this.$data.data[this.source_data].length > 0) {
      this.checkedKeys = []
      this.$data.data[this.source_data].forEach(item => {
        this.checkedKeys.push(item[this.grid_check_key])
      })
    }
  },
  activated() {
    this.isAlive = true
  },
  deactivated() {
    this.isAlive = false
  },
  created() {
    // console.log('>>>>>>1>>>>', this)
    // console.log('>>>>>>2>>>>', this['$data'])
    // console.log('>>>>>>3>>>>', this['$data'].scope)
    // console.log('>>>>>>4>>>>', this['$data']['$scope'])
    // console.log('>>>>>>5>>>>', this.sourceData)
    console.log('>>>>>>6>>>>', this.$data.data)
  },
  methods: {
    // 获取排序数据
    dragHandle(item) {
      console.log('获取排序数据>>>>', item[this.grid_child_data])

      return item[this.grid_child_data]
    },
    // 选择显示和隐藏
    onCheck(e, item, index) {
      console.log('onCheck>>>>>', e)
      console.log('item>>>>>', item)
      // this.$set(this.$data.data, this.source_data, data)
      // this.$data.data[this.source_data] = data
      // item['t'] = new Date().getTime()
      // this.$set(this.$data.data[this.source_data], index, item)
      const iotPassGridShow = localStorage.getItem('iot_pass_grid_show') // 获取本地缓存的显示数据

      let obj = {}
      if (iotPassGridShow) {
        obj = JSON.parse(iotPassGridShow)
      }
      obj[this.pageId + '_' + item.id] = e
      localStorage.setItem('iot_pass_grid_show', JSON.stringify(obj))
      this.checkedKeys = e
    },
    onDragEnter(info) {
      console.log(info)
      // expandedKeys 需要受控时设置
      // this.expandedKeys = info.expandedKeys
    },
    onDrop(info, itemP) {
      console.log('info>>>>>', info)
      console.log('itemP>>>>>', itemP)
      const dropKey = info.node.eventKey
      const dragKey = info.dragNode.eventKey
      const dropPos = info.node.pos.split('-')
      const dropPosition = info.dropPosition - Number(dropPos[dropPos.length - 1])
      console.log('dropPosition>>>>', dropPosition)
      console.log('dropKey>>>>', dropKey)
      console.log('dragKey>>>>', dragKey)
      if (dropKey === 'all') {
        return
      }

      const loop = (data, key, callback) => {
        console.log('key>>>>>', key)

        data.forEach((item, index, arr) => {
          // console.log('arr>>>>', arr)

          if (itemP.id + '_' + item[this.grid_check_key] === key) {
            callback(item, index, arr)
            return
          }
          if (item.children) {
             loop(item.children, key, callback)
          }
        })
      }
      const data = [...itemP[this.grid_child_data]]

      // Find dragObject
      let dragObj
      loop(data, dragKey, (item, index, arr) => {
        arr.splice(index, 1)
        dragObj = item
      })
      // if (!info.dropToGap) {
      //   // Drop on the content
      //   loop(data, dropKey, item => {
      //     item.children = item.children || []
      //     // where to insert 示例添加到尾部，可以是随意位置
      //     item.children.push(dragObj)
      //   })
      // } else if (
      //   (info.node.children || []).length > 0 && // Has children
      //   info.node.expanded && // Is expanded
      //   dropPosition === 1 // On the bottom gap
      // ) {
      //   loop(data, dropKey, item => {
      //     item.children = item.children || []
      //     // where to insert 示例添加到尾部，可以是随意位置
      //     item.children.unshift(dragObj)
      //   })
      // } else {

      // }
      let ar
        let i
        loop(data, dropKey, (item, index, arr) => {
          // debugger
          ar = arr
          i = index
          console.log('ar>>>>', ar)

          if (dropPosition === -1) {
            ar.splice(i, 0, dragObj)
          } else {
            ar.splice(i + 1, 0, dragObj)
          }
        })
      console.log('data>>>>', data)
      const iotPassDrag = localStorage.getItem('iot_pass_drag') // 获取本地缓存的排序数据

      // 记录排序数组
      const ids = []
      data.forEach(item => {
        ids.push(item[this.grid_check_key])
      })
      this.dragList = ids
      // this.$set(this.$data.data, this.source_data, data)
      // this.$data.data[this.source_data] = data

      let obj = {}
      if (iotPassDrag) {
        obj = JSON.parse(iotPassDrag)
      }
      obj[this.pageId + '_' + itemP.id] = ids
      localStorage.setItem('iot_pass_drag', JSON.stringify(obj))
    },
    // 设置字体颜色
    fontColorHandle(item, color) {
      if (this.grid_alarm_data) {
        const alarmData = item[this.grid_alarm_data] // 当前阈值设置列表
        const value = item[this.grid_child_value] // 当前数值
        // console.log('alarmData>>>', alarmData)
        // console.log('value>>>', value)

        if (alarmData) {
          let alarmColor = color
          alarmData.forEach(alarmItem => {
            const maxValue = alarmItem[this.grid_alarm_max] // 最大值
            const minValue = alarmItem[this.grid_alarm_min] // 最小值

            if (minValue !== undefined && maxValue !== undefined) {
              if (value >= minValue && value <= maxValue) {
                alarmColor = alarmItem[this.grid_alarm_color] || color // 阈值对应颜色
              }
            }
          })

          return alarmColor
        } else {
          return color
        }
      } else {
        return color
      }
    },
    // 判断key是否存在
    isHasKey(key, defaultKey) {
      return key || defaultKey
    },
    /**
     * 添加amis事件动作:
     * 在这里设置自定义组件对外暴露的动作，其他组件可以通过组件动作触发自定义组件的对应动作
     */
    doAction(action, args) {
      const actionType = action ? action.actionType : ''
      if (actionType === 'message') {
        // 接收外部组件的事件动作'message'
        alert('您触发了自定义组件的事件动作[message]')
      } else {
        console.log('自定义组件中监听到的事件动作：', action, ', 事件参数：', args)
      }
    }
  }
}
</script>
<style lang="less" scoped>

.grid {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 32px;

  .grid-box {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 32px;

    .grid-box-name {
      display: flex;
      align-items: center;
      justify-content: space-between;
      // padding: 0 0 20px 0;
      margin-bottom: 20px;
      font-size: 18px;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: bold;
      border-radius: 12px 12px 0 0;
    }

    .grid-box-data {
      display: flex;
      flex-wrap: wrap;
      .grid-box-item {
        display: flex;
        height: 66px;
        background-size: 100% 100%;
        margin-right: 10px;
        .grid-box-item-icon {
          height: 100%;
          margin-right: 19px;
        }

        .grid-box-item-info {
          flex: 1;
          height: 100%;
          padding: 10px 0 10px 10px;
          display: flex;
          flex-direction: column;
          justify-content: space-around;

          .grid-box-item-info-name {
            font-size: 14px;
            font-family: PingFang SC-Medium, PingFang SC;
            font-weight: 500;
            color: #575d6c;
          }

          .grid-box-item-info-num {
            font-size: 16px;
            font-family: Source Han Sans CN-Bold, Source Han Sans CN;
            font-weight: bold;
            color: #1c1f27;
            line-height: 38px;
          }
        }
      }
    }

    .grid-control {
      // position: absolute;
      // top: 10px;
      // right: 10px;
    }

  }

}
.grid_item_hide {
  display: none !important;
}

</style>

<style>
.ant-tree-switcher:has(> span.iconClass) {
  height: 0 !important;
}

.nodeClass .draggable{
  cursor: move !important;
}
</style>
