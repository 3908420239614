/* eslint-disable no-template-curly-in-string */
/**
 * @file 编辑器扩展, 增加自定义组件
 */
import { BasePlugin, registerEditorPlugin } from 'amis-editor'

export class InfoGridPlugin extends BasePlugin {
  // 关联渲染器名字
  rendererName = 'com-pie-chart';
  $schema = '/schemas/UnkownSchema.json';
  notRenderFormZone = true;
  // 组件名称（组件面板显示的Title）
  name = '二维码按钮';
  order=0; // 排序
  description = '二维码按钮信息';
  tags = ['布局容器', '表格'];
  icon = 'fa fa-file-code-o';
  scaffold = { // 插入到页面时需要
    'type': 'button',
    'label': '二维码',
    'onEvent': {
      'click': {
        'actions': [
          {
            'ignoreError': false,
            'actionType': 'dialog',
            'dialog': {
              'type': 'dialog',
              'title': '设备二维码',
              'actions': [
              ],
              'body': [
                {
                  'type': 'flex',
                  'className': 'p-1',
                  'items': [
                    {
                      'type': 'container',
                      'body': [
                        {
                          'type': 'qrcode',
                          'value': '${deviceNo}',
                          'id': 'u:7db8f59258f9',
                          'codeSize': 128,
                          'level': 'M'
                        }
                      ],
                      'size': 'xs',
                      'style': {
                        'position': 'static',
                        'display': 'block',
                        'flex': '1 1 auto',
                        'flexGrow': 1,
                        'flexBasis': 'auto'
                      },
                      'wrapperBody': false,
                      'isFixedHeight': false,
                      'isFixedWidth': false,
                      'id': 'u:a416549f7749'
                    }
                  ],
                  'style': {
                    'position': 'static',
                    'flexWrap': 'nowrap',
                    'boxShadow': ' 0px 0px 0px 0px transparent',
                    'justifyContent': 'center',
                    'alignItems': 'flex-start'
                  },
                  'id': 'u:79613b18b0b1',
                  'isFixedHeight': false,
                  'isFixedWidth': false,
                  'stickyStatus': false
                }
              ],
              'className': 'app-popover',
              'id': 'u:699475903f63',
              'closeOnEsc': false,
              'size': 'sm'
            }
          }
        ],
        'weight': 0
      }
    },
    'id': 'u:98755c116039',
    'themeCss': {
      'className': {
        'background:default': 'rgba(0, 82, 199, 0.06)',
        'font:default': {
          'color': '#0052c7'
        },
        'border:default': {
          'border': '0'
        },
        'background:hover': 'rgba(0, 82, 199, 0.06)',
        'font:hover': {
          'color': '#0052c7'
        },
        'border:hover': {
          'border': '0'
        },
        'background:active': 'rgba(0, 82, 199, 0.06)',
        'font:active': {
          'color': '#0052c7'
        },
        'border:active': {
          'border': '0'
        }
      }
    },
    'hiddenOn': "${permissions!='*:*:*'&&!ARRAYINCLUDES(permissions,'gather:device:qrcode')}",
    'className': 'className-98755c116039',
    'icon': 'fas fa-qrcode'
  };
  previewSchema = { // 组件面板预览时需要
    'type': 'button',
    'label': '二维码',
    'onEvent': {
      'click': {
        'actions': [
          {
            'ignoreError': false,
            'actionType': 'dialog',
            'dialog': {
              'type': 'dialog',
              'title': '设备二维码',
              'actions': [
              ],
              'body': [
                {
                  'type': 'flex',
                  'className': 'p-1',
                  'items': [
                    {
                      'type': 'container',
                      'body': [
                        {
                          'type': 'qrcode',
                          'value': '${deviceNo}',
                          'id': 'u:7db8f59258f9',
                          'codeSize': 128,
                          'level': 'M'
                        }
                      ],
                      'size': 'xs',
                      'style': {
                        'position': 'static',
                        'display': 'block',
                        'flex': '1 1 auto',
                        'flexGrow': 1,
                        'flexBasis': 'auto'
                      },
                      'wrapperBody': false,
                      'isFixedHeight': false,
                      'isFixedWidth': false,
                      'id': 'u:a416549f7749'
                    }
                  ],
                  'style': {
                    'position': 'static',
                    'flexWrap': 'nowrap',
                    'boxShadow': ' 0px 0px 0px 0px transparent',
                    'justifyContent': 'center',
                    'alignItems': 'flex-start'
                  },
                  'id': 'u:79613b18b0b1',
                  'isFixedHeight': false,
                  'isFixedWidth': false,
                  'stickyStatus': false
                }
              ],
              'className': 'app-popover',
              'id': 'u:699475903f63',
              'closeOnEsc': false,
              'size': 'sm'
            }
          }
        ],
        'weight': 0
      }
    },
    'id': 'u:98755c116039',
    'themeCss': {
      'className': {
        'background:default': 'rgba(0, 82, 199, 0.06)',
        'font:default': {
          'color': '#0052c7'
        },
        'border:default': {
          'border': '0'
        },
        'background:hover': 'rgba(0, 82, 199, 0.06)',
        'font:hover': {
          'color': '#0052c7'
        },
        'border:hover': {
          'border': '0'
        },
        'background:active': 'rgba(0, 82, 199, 0.06)',
        'font:active': {
          'color': '#0052c7'
        },
        'border:active': {
          'border': '0'
        }
      }
    },
    'hiddenOn': "${permissions!='*:*:*'&&!ARRAYINCLUDES(permissions,'gather:device:qrcode')}",
    'className': 'className-98755c116039',
    'icon': 'fas fa-qrcode'
  };

  // 容器类组件必需字段
  // regions = [
  //   {
  //     key: 'body',
  //     label: '内容区'
  //   }
  // ];

  panelTitle = '配置'; // 右侧属性面板Title

  panelControls = [ // 右侧属性面板配置项

  ];
}

registerEditorPlugin(InfoGridPlugin)
