<template>
  <div class="amap-wrapper">
    <a-input v-if="amap_is_search" id="input" v-model="searchName" placeholder="请输入地址查询" class="search-box"/>
    <el-amap
      class="amap-box"
      :style="{ width: amap_width, height: amap_height }"
      :vid="'amap-vue-search_'+amap_create_time+''"
      ref="map"
      :center="center"
      :zoom="zoom"
      :plugin="plugin"
      :events="events"
    >
      <el-amap-marker
        v-for="(marker, index) in markers"
        :position="[marker.lng, marker.lat]"
        :key="index"
        :label="{ content: marker.name }"
      ></el-amap-marker>
    </el-amap>
  </div>
</template>

<script>
import Vue from 'vue'
// 高德地图
import VueAMap from 'vue-amap'
var self = null
export default {
  props: {
    width: {
      type: String || Number,
      default: '90%'
    },
    height: {
      type: String || Number,
      default: '500px'
    },
    isSearch: {
      type: Boolean,
      default: true
    },
    province: {
      type: String,
      default: ''
    },
    city: {
      type: String,
      default: ''
    },
    area: {
      type: String,
      default: ''
    },
    address: {
      type: String,
      default: ''
    },
    lat: {
      type: [String, Number],
      default: null
    },
    lng: {
      type: [String, Number],
      default: null
    }
  },
  data() {
    self = this
    return {
      searchName: null,
      zoom: 12,
      center: [116.396304, 39.909847],
      events: null,
      markers: [],
      latitude: '11111111',
      searchOption: {
        city: '',
        citylimit: false
      },
      plugin: [],
      errorInfo: {
        'INVALID_USER_KEY': 'key不正确或过期',
        'SERVICE_NOT_AVAILABLE': '服务不可用',
        'INVALID_USER_SCODE': '安全码未通过验证',
        'INVALID_USER_DOMAIN': '绑定域名无效',
        'USERKEY_PLAT_NOMATCH': '请求key与绑定平台不符',
        'NOT_SUPPORT_HTTPS': '服务不支持https请求',
        'INSUFFICIENT_PRIVILEGES': '权限不足，服务请求被拒绝',
        'USER_KEY_RECYCLED': 'Key被删除',
        'INVALID_PARAMS': '请求参数非法',
        'MISSING_REQUIRED_PARAMS': '缺少必填参数',
        'UNKNOWN_ERROR': '其他未知错误',
        'OUT_OF_SERVICE': '规划点（包括起点、终点、途经点）不在中国陆地范围内',
        'NO_ROADS_NEARBY': '规划点（起点、终点、途经点）附近搜不到路',
        'ROUTE_FAIL': '路线计算失败，通常是由于道路连通关系导致',
        'OVER_DIRECTION_RANGE': '起点终点距离过长',
        'ENGINE_RESPONSE_DATA_ERROR': '服务响应失败'
      }
    }
  },
  beforeMount() {
    VueAMap.initAMapApiLoader({
      key: this.amap_key,
      plugin: ['AMap.Autocomplete', 'AMap.Geocoder', 'AMap.PlaceSearch', 'AMap.Scale', 'AMap.OverView', 'AMap.ToolBar', 'AMap.MapType', 'AMap.PolyEditor', 'AMap.CircleEditor', 'MarkerClusterer'],
      v: '1.4.4'
    })
    window._AMapSecurityConfig = {
      securityJsCode: this.amap_security
    }
  },
  mounted() {
    console.log('self>>>>>1>>>>>', this)

    this.events = {
      click: (e) => {
        // const that = this
        if (!e) {
          return
        }
        if (!this.amap_is_click) {
          // 不支持点击
          return
        }
        const positionArr = []
        positionArr.push(e.lnglat.lng)
        positionArr.push(e.lnglat.lat)
        console.log('e>>>>>', e)
        console.log('Vue>>>>>', Vue.markers)
        console.log('self>>>>>', self)
        console.log('this>>>>>', this)

        this.markers = []
        // this.markers.push({ lng: positionArr[0], lat: positionArr[1] });
        this.geoCoder(positionArr)
      }
    }
    const pluginObj = {
      pName: 'Autocomplete',
      input: 'input',
      events: {}
    }
    pluginObj.events = {
      init: (o) => {
        console.log('插件初始化>>>>', o)
        console.log('插件初始化>>>>this>>>>', this)

        o.on('select', (e) => {
          console.log('搜索地图>>>>', e)
          console.log('搜索地图>>>>this>>>>', this)

          this.markers = []
          console.log(e)
          this.center = [e.poi.location.lng, e.poi.location.lat]
          this.markers = [{ lng: e.poi.location.lng, lat: e.poi.location.lat, name: e.poi.name }]
          this.geoCoder([e.poi.location.lng, e.poi.location.lat])
        })
      }
    }
    this.plugin = []
    this.plugin.push(pluginObj)
  },
  methods: {
    init(lng, lat, label) {
      if (lng && lat) {
        this.center = [lng, lat]
        this.markers = [
          { lng: this.center[0], lat: this.center[1], name: label }
        ]
      } else {
        this.center = [116.396304, 39.909847]
      }
    },
    geoCoder(positionArr) {
      // 获取地址经纬度和省市区
      var that = this
      // 这里通过高德 SDK 完成。
      // eslint-disable-next-line no-undef
      var geocoder = new AMap.Geocoder({
        radius: 1000,
        extensions: 'all'
      })
      console.log('positionArr>>>>>', positionArr)

      geocoder.getAddress(positionArr, function (status, result) {
        console.log('getAddress>>>>status>>>>>', status)
        console.log('getAddress>>>>result>>>>', result)

        if (status === 'complete' && result.info === 'OK') {
          if (result && result.regeocode) {
            const detailObj = {
              addressComponent: result.regeocode.addressComponent,
              detailedAddress: result.regeocode.formattedAddress,
              lng: positionArr[0],
              lat: positionArr[1]
            }
            that.markers.push({
              lng: positionArr[0],
              lat: positionArr[1],
              name: result.regeocode.formattedAddress
            })
            console.log('地址信息>>>>', detailObj)
            console.log('地址标记点信息>>>>', that.markers)
            console.log('地址标记点信息>>>>data', that.$data)
            console.log('地址标记点信息>>>>data', that.$data.amap_longitude)
            console.log('地址标记点信息>>>>data', that.$data.data)
            const dataCopy = JSON.parse(JSON.stringify(that.$data.data))
            dataCopy[that.$data.amap_longitude] = detailObj.lng
            dataCopy[that.amap_latitude] = detailObj.lat
            dataCopy[that.amap_info] = detailObj
            // that.$data.data = dataCopy
            that.$set(that.$data, 'defaultData', dataCopy)
            console.log('设置标记点之后的数据>>>>>>', that.$data)
            that.$emit('update', that.data)
            // that.$emit('update:province', detailObj.addressComponent.province)
            // that.$emit('update:city', detailObj.addressComponent.city || detailObj.addressComponent.province)
            // that.$emit('update:area', detailObj.addressComponent.district)
            // that.$emit('update:address', detailObj.detailedAddress)
            // that.$emit('update:lat', detailObj.lat)
            // that.$emit('update:lng', detailObj.lng)
            // that.$emit('getInfo', detailObj)
          }
        } else {
          if (that.errorInfo[result]) {
            that.$message.error(that.errorInfo[result])
          }
        }
      })
    },
    /**
     * 添加amis事件动作:
     * 在这里设置自定义组件对外暴露的动作，其他组件可以通过组件动作触发自定义组件的对应动作
     */
     doAction(action, args) {
      const actionType = action ? action.actionType : ''
      if (actionType === 'message') {
        // 接收外部组件的事件动作'message'
        alert('您触发了自定义组件的事件动作[message]')
      } else {
        console.log('自定义组件中监听到的事件动作：', action, ', 事件参数：', args)
      }
    }
  }
}
</script>
<style lang="less" scoped>
::v-deep{
  .el-vue-search-box-container {
    width: 270px;
  }
}
.amap-box {
  ::v-deep{
    .amap-marker-label {
      transform: translate(-40%, -24px);
      border: none;
      box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.5);
    }
  }
}

.amap-wrapper {
  text-align: right;
  position: relative;
}
.amap-box {
  width: 90%;
  // margin-left: 5%;
}
.search-box {
  width: 30%;
  position: absolute;
  top: 15px;
  left: 3%;
  z-index: 999;
}
</style>
