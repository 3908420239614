import defaultSettings from '@/config/defaultSettings'
export const ACCESS_TOKEN = defaultSettings.storageKey + 'access_token'

export const SIDE_COLLAPSED = defaultSettings.storageKey + 'side_collapsed'
export const TOGGLE_MOBILE_TYPE = defaultSettings.storageKey + 'is_mobile'
export const TOGGLE_NAV_THEME = defaultSettings.storageKey + 'nav_theme'
export const TOGGLE_LAYOUT = defaultSettings.storageKey + 'layout'
export const TOGGLE_FIXED_HEADER = defaultSettings.storageKey + 'fixed_header'
export const TOGGLE_FIXED_SIDEBAR = defaultSettings.storageKey + 'fixed_sidebar'
export const TOGGLE_CONTENT_WIDTH = defaultSettings.storageKey + 'content_width'
export const TOGGLE_COLOR = defaultSettings.storageKey + 'color'
export const TOGGLE_WEAK = defaultSettings.storageKey + 'weak'
export const TOGGLE_MULTI_TAB = defaultSettings.storageKey + 'multi_tab'
export const APP_LANGUAGE = defaultSettings.storageKey + 'app_language'

export const LOGIN_USERNAME = defaultSettings.storageKey + 'login_username'
export const LOGIN_PASSWORD = defaultSettings.storageKey + 'login_password'
export const LOGIN_REMEMBERME = defaultSettings.storageKey + 'login_rememberme'

export const CONTENT_WIDTH_TYPE = {
  Fluid: 'Fluid',
  Fixed: 'Fixed'
}

export const NAV_THEME = {
  LIGHT: 'light',
  DARK: 'dark'
}

export const TABLE_SIZE = defaultSettings.storageKey + 'table_size'
export const TABLE_BORDERED = defaultSettings.storageKey + 'table_bordered'
export const HIDE_FOOTER = defaultSettings.storageKey + 'hide_footer'
export const ACTIVE_PAGE_TITLE = defaultSettings.storageKey + 'active_page_title'
