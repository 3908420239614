/* eslint-disable no-template-curly-in-string */
/**
 * @file 编辑器扩展, 增加自定义组件
 */
import { BasePlugin, registerEditorPlugin } from 'amis-editor'

export class InfoGridPlugin extends BasePlugin {
  // 关联渲染器名字
  rendererName = 'com-pie-chart';
  $schema = '/schemas/UnkownSchema.json';
  notRenderFormZone = true;
  // 组件名称（组件面板显示的Title）
  name = '用户动态';
  order=0; // 排序
  description = '用户动态展示信息';
  tags = ['布局容器', '驾驶舱'];
  icon = 'fa fa-file-code-o';
  scaffold = { // 插入到页面时需要
    'type': 'panel',
    'title': '用户动态',
    'body': [
      {
        'type': 'list',
        'listItem': {
          'body': [
            {
              'type': 'flex',
              'id': 'u:9cb66aa62c2d',
              'style': {
                'position': 'relative',
                'inset': 'auto',
                'flexWrap': 'nowrap',
                'boxShadow': ' 0px 0px 0px 0px transparent',
                'alignItems': 'center'
              },
              'className': 'p-1',
              'items': [
                {
                  'type': 'container',
                  'body': [
                    {
                      'type': 'avatar',
                      'src': 'https://suda.cdn.bcebos.com/amis/images/alice-macaw.jpg',
                      'id': 'u:eab02831786d',
                      'style': {
                        'width': 56,
                        'height': 56,
                        'borderRadius': 28
                      },
                      'showtype': 'image'
                    }
                  ],
                  'size': 'xs',
                  'style': {
                    'position': 'static',
                    'display': 'flex',
                    'flex': '0 0 150px',
                    'flexBasis': '56px',
                    'boxShadow': ' 0px 0px 0px 0px transparent',
                    'flexWrap': 'nowrap',
                    'justifyContent': 'center',
                    'alignItems': 'center',
                    'overflowX': 'visible',
                    'height': '56px',
                    'overflowY': 'visible'
                  },
                  'wrapperBody': false,
                  'isFixedHeight': true,
                  'isFixedWidth': false,
                  'id': 'u:14a8e2240b3b'
                },
                {
                  'type': 'container',
                  'body': [
                    {
                      'type': 'tpl',
                      'tpl': '${userName}的动态',
                      'wrapperComponent': '',
                      'id': 'u:362040b11511',
                      'style': {
                        'boxShadow': ' 0px 0px 0px 0px transparent',
                        'fontWeight': '700'
                      }
                    },
                    {
                      'type': 'tpl',
                      'tpl': '${message}',
                      'wrapperComponent': '',
                      'id': 'u:9acf13753334',
                      'style': {
                        'boxShadow': ' 0px 0px 0px 0px transparent'
                      }
                    }
                  ],
                  'size': 'xs',
                  'style': {
                    'position': 'static',
                    'display': 'flex',
                    'flex': '1 1 auto',
                    'flexGrow': 1,
                    'flexBasis': 'auto',
                    'boxShadow': ' 0px 0px 0px 0px transparent',
                    'flexWrap': 'nowrap',
                    'flexDirection': 'column',
                    'justifyContent': 'space-between',
                    'alignItems': 'flex-start',
                    'paddingTop': '15px',
                    'paddingRight': '0',
                    'paddingBottom': '15px',
                    'paddingLeft': '0',
                    'marginTop': '0',
                    'marginRight': '0',
                    'marginBottom': '0',
                    'marginLeft': '10px'
                  },
                  'wrapperBody': false,
                  'isFixedHeight': false,
                  'isFixedWidth': false,
                  'id': 'u:f2d17cd1f086'
                }
              ],
              'label': '',
              'isFixedHeight': false,
              'isFixedWidth': false
            }
          ],
          'actions': [
          ],
          'id': 'u:5f1e348fe2b6'
        },
        'id': 'u:24833a1a6928',
        'source': '${operateLog}',
        'showHeader': false,
        'className': 'overflow-auto h-80 no-border',
        'itemClassName': 'no-border'
      }
    ],
    'actions': [
    ],
    'affixFooter': false,
    'bodyClassName': 'p p-b-md'
  };
  previewSchema = { // 组件面板预览时需要
    'type': 'panel',
    'title': '用户动态',
    'body': [
      {
        'type': 'list',
        'listItem': {
          'body': [
            {
              'type': 'flex',
              'id': 'u:9cb66aa62c2d',
              'style': {
                'position': 'relative',
                'inset': 'auto',
                'flexWrap': 'nowrap',
                'boxShadow': ' 0px 0px 0px 0px transparent',
                'alignItems': 'center'
              },
              'className': 'p-1',
              'items': [
                {
                  'type': 'container',
                  'body': [
                    {
                      'type': 'avatar',
                      'src': 'https://suda.cdn.bcebos.com/amis/images/alice-macaw.jpg',
                      'id': 'u:eab02831786d',
                      'style': {
                        'width': 56,
                        'height': 56,
                        'borderRadius': 28
                      },
                      'showtype': 'image'
                    }
                  ],
                  'size': 'xs',
                  'style': {
                    'position': 'static',
                    'display': 'flex',
                    'flex': '0 0 150px',
                    'flexBasis': '56px',
                    'boxShadow': ' 0px 0px 0px 0px transparent',
                    'flexWrap': 'nowrap',
                    'justifyContent': 'center',
                    'alignItems': 'center',
                    'overflowX': 'visible',
                    'height': '56px',
                    'overflowY': 'visible'
                  },
                  'wrapperBody': false,
                  'isFixedHeight': true,
                  'isFixedWidth': false,
                  'id': 'u:14a8e2240b3b'
                },
                {
                  'type': 'container',
                  'body': [
                    {
                      'type': 'tpl',
                      'tpl': '${userName}的动态',
                      'wrapperComponent': '',
                      'id': 'u:362040b11511',
                      'style': {
                        'boxShadow': ' 0px 0px 0px 0px transparent',
                        'fontWeight': '700'
                      }
                    },
                    {
                      'type': 'tpl',
                      'tpl': '${message}',
                      'wrapperComponent': '',
                      'id': 'u:9acf13753334',
                      'style': {
                        'boxShadow': ' 0px 0px 0px 0px transparent'
                      }
                    }
                  ],
                  'size': 'xs',
                  'style': {
                    'position': 'static',
                    'display': 'flex',
                    'flex': '1 1 auto',
                    'flexGrow': 1,
                    'flexBasis': 'auto',
                    'boxShadow': ' 0px 0px 0px 0px transparent',
                    'flexWrap': 'nowrap',
                    'flexDirection': 'column',
                    'justifyContent': 'space-between',
                    'alignItems': 'flex-start',
                    'paddingTop': '15px',
                    'paddingRight': '0',
                    'paddingBottom': '15px',
                    'paddingLeft': '0',
                    'marginTop': '0',
                    'marginRight': '0',
                    'marginBottom': '0',
                    'marginLeft': '10px'
                  },
                  'wrapperBody': false,
                  'isFixedHeight': false,
                  'isFixedWidth': false,
                  'id': 'u:f2d17cd1f086'
                }
              ],
              'label': '',
              'isFixedHeight': false,
              'isFixedWidth': false
            }
          ],
          'actions': [
          ],
          'id': 'u:5f1e348fe2b6'
        },
        'id': 'u:24833a1a6928',
        'source': '${operateLog}',
        'showHeader': false,
        'className': 'overflow-auto h-80 no-border',
        'itemClassName': 'no-border'
      }
    ],
    'actions': [
    ],
    'affixFooter': false,
    'bodyClassName': 'p p-b-md'
  };

  // 容器类组件必需字段
  // regions = [
  //   {
  //     key: 'body',
  //     label: '内容区'
  //   }
  // ];

  panelTitle = '配置'; // 右侧属性面板Title

  panelControls = [ // 右侧属性面板配置项
    // {
    //   type: 'tabs',
    //   tabsMode: 'line',
    //   className: 'editor-prop-config-tabs',
    //   contentClassName: 'no-border editor-prop-config-tabs-cont hoverShowScrollBar',
    //   linksClassName: 'editor-prop-config-tabs-links aa',
    //   tabs: [
    //     {
    //       title: '常规',
    //       className: 'p-none',
    //       controls: [
    //         {
    //           'type': 'collapse-group',
    //           className: 'ae-formItemControl ae-styleControl',
    //           'activeKey': [
    //             'grid_11', 'grid_12'
    //           ],
    //           body: [
    //             {
    //               'type': 'collapse',
    //               'key': 'grid_11',
    //               'header': '基本',
    //               headingClassName: 'ae-formItemControl-header',
    //               bodyClassName: 'ae-formItemControl-body',
    //               'body': [
    //                 {
    //                   type: 'input-text',
    //                   name: 'source_data',
    //                   label: '数据源',
    //                   value: 'deviceData',
    //                   // 'mode': 'inline',
    //                   'required': true

    //                 },
    //                 {
    //                   type: 'input-text',
    //                   name: 'grid_name',
    //                   label: '子设备名称',
    //                   value: 'deviceName',
    //                   // 'mode': 'inline',
    //                   'required': true
    //                 }
    //               ]
    //             },
    //             {
    //               'type': 'collapse',
    //               'key': 'grid_12',
    //               'header': '采集项',
    //               headingClassName: 'ae-formItemControl-header',
    //               bodyClassName: 'ae-formItemControl-body',
    //               'body': [
    //                 {
    //                   type: 'input-text',
    //                   name: 'grid_child_data',
    //                   label: '采集项数据',
    //                   value: 'data',
    //                   'required': true

    //                 },
    //                 {
    //                   type: 'input-text',
    //                   name: 'grid_child_name',
    //                   label: '名称',
    //                   value: 'attrName',
    //                   // 'mode': 'inline',
    //                   'required': true
    //                 },
    //                 {
    //                   type: 'input-text',
    //                   name: 'grid_child_value',
    //                   label: '数值',
    //                   value: 'value',
    //                   // 'mode': 'inline',
    //                   'required': true
    //                 },
    //                 {
    //                   type: 'input-text',
    //                   name: 'grid_child_unit',
    //                   label: '单位',
    //                   value: 'attrUnit'
    //                   // 'mode': 'inline'
    //                 },
    //                 {
    //                   type: 'input-text',
    //                   name: 'grid_child_icon',
    //                   label: '图标',
    //                   value: 'icon'
    //                   // 'mode': 'inline'
    //                 }
    //               ]
    //             }
    //           ]
    //         }

    //       ]
    //     },
    //     {
    //       title: '外观',
    //       className: 'p-none',
    //       controls: [
    //         {
    //           'type': 'collapse-group',
    //           className: 'ae-formItemControl ae-styleControl',
    //           'activeKey': [
    //             'grid_21', 'grid_22', 'grid_23'
    //           ],
    //           'body': [
    //             {
    //               'type': 'collapse',
    //               'key': 'grid_21',
    //               'header': '行数量',
    //               headingClassName: 'ae-formItemControl-header',
    //               bodyClassName: 'ae-formItemControl-body',
    //               'body': [
    //                 {
    //                   type: 'input-number',
    //                   name: 'grid_num',
    //                   label: '一行显示数量',
    //                   value: 5
    //                   // 'mode': 'inline'
    //                 }
    //               ]
    //             },
    //             {
    //               'type': 'collapse',
    //               'key': 'grid_22',
    //               'header': '显隐/排序设置',
    //               headingClassName: 'ae-formItemControl-header',
    //               bodyClassName: 'ae-formItemControl-body',
    //               'body': [
    //                 {
    //                   'type': 'switch',
    //                   'label': '状态',
    //                   'option': '',
    //                   'name': 'grid_edit',
    //                   'falseValue': false,
    //                   'trueValue': true,
    //                   'value': true,
    //                   'onText': '启用',
    //                   'offText': '禁用'
    //                 },
    //                 {
    //                   'type': 'input-text',
    //                   'label': '显隐key',
    //                   'name': 'grid_check_key',
    //                   'value': 'attrId',
    //                   'hiddenOn': '${AND(!grid_edit)}'
    //                 }
    //               ]
    //             },
    //             {
    //               'type': 'collapse',
    //               'key': 'grid_23',
    //               'header': '间距',
    //               headingClassName: 'ae-formItemControl-header',
    //               bodyClassName: 'ae-formItemControl-body',
    //               'body': [
    //                 {
    //                   type: 'input-number',
    //                   name: 'grid_margin_bottom',
    //                   label: '行间距',
    //                   value: 5
    //                   // 'mode': 'inline'
    //                 },
    //                 {
    //                   type: 'input-number',
    //                   name: 'grid_padding_top',
    //                   label: '上边距',
    //                   value: 10
    //                   // 'mode': 'inline'
    //                 },
    //                 {
    //                   type: 'input-number',
    //                   name: 'grid_padding_left',
    //                   label: '左边距',
    //                   value: 0
    //                   // 'mode': 'inline'
    //                 },
    //                 {
    //                   type: 'input-number',
    //                   name: 'grid_padding_bottom',
    //                   label: '下边距',
    //                   value: 10
    //                   // 'mode': 'inline'
    //                 },
    //                 {
    //                   type: 'input-number',
    //                   name: 'grid_padding_right',
    //                   label: '右边距',
    //                   value: 0
    //                   // 'mode': 'inline'
    //                 }
    //               ]
    //             },
    //             {
    //               'type': 'collapse',
    //               'key': 'grid_23',
    //               'header': '颜色',
    //               headingClassName: 'ae-formItemControl-header',
    //               bodyClassName: 'ae-formItemControl-body',
    //               'body': [
    //                 {
    //                   'type': 'input-color',
    //                   'label': '背景色',
    //                   'name': 'grid_background',
    //                   'format': 'rgba',
    //                   'value': 'rgba(255, 255, 255, 0)',
    //                   'labelRemark': {
    //                     'icon': 'fa fa-question-circle',
    //                     'trigger': [
    //                       'hover'
    //                     ],
    //                     'className': 'Remark--warning',
    //                     'placement': 'top',
    //                     'content': '设置整个区域的背景色'
    //                   },
    //                   'allowCustomColor': true
    //                   // 'mode': 'inline'
    //                 },
    //                 {
    //                   'type': 'input-color',
    //                   'label': '名称颜色',
    //                   'name': 'grid_name_color',
    //                   'format': 'rgba',
    //                   'value': 'rgb(87, 93, 108, 1)',
    //                   'labelRemark': {
    //                     'icon': 'fa fa-question-circle',
    //                     'trigger': [
    //                       'hover'
    //                     ],
    //                     'className': 'Remark--warning',
    //                     'placement': 'top',
    //                     'content': '设置名称颜色'
    //                   },
    //                   'resetValue': 'rgb(87, 93, 108, 1)',
    //                   'allowCustomColor': true
    //                   // 'mode': 'inline'
    //                 },
    //                 {
    //                   'type': 'input-color',
    //                   'label': '数值颜色',
    //                   'name': 'grid_value_color',
    //                   'format': 'rgba',
    //                   'value': 'rgb(28, 31, 39, 1)',
    //                   'labelRemark': {
    //                     'icon': 'fa fa-question-circle',
    //                     'trigger': [
    //                       'hover'
    //                     ],
    //                     'className': 'Remark--warning',
    //                     'placement': 'top',
    //                     'content': '设置数值颜色'
    //                   },
    //                   'resetValue': 'rgb(28, 31, 39, 1)',
    //                   'allowCustomColor': true
    //                   // 'mode': 'inline'
    //                 }
    //               ]
    //             }
    //           ]
    //         }
    //       ]
    //     },
    //     {
    //       title: '阈值',
    //       className: 'p-none',
    //       controls: [
    //         {
    //           'type': 'collapse-group',
    //           className: 'ae-formItemControl ae-styleControl',
    //           'activeKey': [
    //             'grid_31', 'grid_32'
    //           ],
    //           'body': [
    //             {
    //               'type': 'collapse',
    //               'key': 'grid_31',
    //               'header': '阈值数据设置',
    //               headingClassName: 'ae-formItemControl-header',
    //               bodyClassName: 'ae-formItemControl-body',
    //               'body': [
    //                 {
    //                   type: 'input-text',
    //                   name: 'grid_alarm_data',
    //                   label: '阈值数据',
    //                   value: 'threshold'
    //                   // 'mode': 'inline'
    //                 }
    //               ]
    //             },
    //             {
    //               'type': 'collapse',
    //               'key': 'grid_32',
    //               'header': '阈值显示设置',
    //               headingClassName: 'ae-formItemControl-header',
    //               bodyClassName: 'ae-formItemControl-body',
    //               'body': [
    //                 {
    //                   type: 'input-text',
    //                   name: 'grid_alarm_max',
    //                   label: '对应最大值',
    //                   value: 'max'
    //                   // 'mode': 'inline'
    //                 },
    //                 {
    //                   type: 'input-text',
    //                   name: 'grid_alarm_min',
    //                   label: '对应最小值',
    //                   value: 'min'
    //                   // 'mode': 'inline'
    //                 },
    //                 {
    //                   'type': 'input-text',
    //                   'label': '对应显示颜色',
    //                   'name': 'grid_alarm_color',
    //                   value: 'color'
    //                 }
    //               ]
    //             }
    //           ]
    //         }
    //       ]
    //     }
    //   ]

    // }

  ];
}

registerEditorPlugin(InfoGridPlugin)
