/* eslint-disable no-template-curly-in-string */
/**
 * @file 编辑器扩展, 增加自定义组件
 */
import { BasePlugin, registerEditorPlugin } from 'amis-editor'

export class InfoGridPlugin extends BasePlugin {
  // 关联渲染器名字
  rendererName = 'com-pie-chart';
  $schema = '/schemas/UnkownSchema.json';
  notRenderFormZone = true;
  // 组件名称（组件面板显示的Title）
  name = '映射';
  order=0; // 排序
  description = '映射信息';
  tags = ['布局容器', '表格'];
  icon = 'fa fa-file-code-o';
  scaffold = { // 插入到页面时需要
    'type': 'mapping',
    'value': 1,
    'map': {
      '1': '异常报警',
      '2': '故障报警',
      '3': '业务报警'
    },
    'itemSchema': [
      {
        'type': 'tag',
        'label': '${item}',
        'id': 'u:f7f1287c5655',
        'displayMode': 'normal',
        'hiddenOn': "${AND(${item}!='异常报警')}",
        'style': {
          'color': '#1A951A',
          'fontSize': '1.6vh'
        },
        'color': '#D6F4D3',
        'className': 'r-2x'
      },
      {
        'type': 'tag',
        'label': '${item}',
        'id': 'u:c9c8fd16ef60',
        'displayMode': 'normal',
        'hiddenOn': "${AND(${item}!='故障报警')}",
        'style': {
          'color': '#DA6A00',
          'fontSize': '1.6vh'
        },
        'color': '#FFE7CC',
        'className': 'r-2x'
      },
      {
        'type': 'tag',
        'label': '${item}',
        'id': 'u:3c6cf294063d',
        'displayMode': 'normal',
        'hiddenOn': "${AND(${item}!='业务报警')}",
        'style': {
          'color': '#2548B4',
          'fontSize': '1.6vh'
        },
        'color': '#b8c7f5',
        'className': 'r-2x'
      }
    ],
    'id': 'u:ea46385d60d4'
  };
  previewSchema = { // 组件面板预览时需要
    'type': 'mapping',
    'value': 1,
    'map': {
      '1': '异常报警',
      '2': '故障报警',
      '3': '业务报警'
    },
    'itemSchema': [
      {
        'type': 'tag',
        'label': '${item}',
        'id': 'u:f7f1287c5655',
        'displayMode': 'normal',
        'hiddenOn': "${AND(${item}!='异常报警')}",
        'style': {
          'color': '#1A951A',
          'fontSize': '1.6vh'
        },
        'color': '#D6F4D3',
        'className': 'r-2x'
      },
      {
        'type': 'tag',
        'label': '${item}',
        'id': 'u:c9c8fd16ef60',
        'displayMode': 'normal',
        'hiddenOn': "${AND(${item}!='故障报警')}",
        'style': {
          'color': '#DA6A00',
          'fontSize': '1.6vh'
        },
        'color': '#FFE7CC',
        'className': 'r-2x'
      },
      {
        'type': 'tag',
        'label': '${item}',
        'id': 'u:3c6cf294063d',
        'displayMode': 'normal',
        'hiddenOn': "${AND(${item}!='业务报警')}",
        'style': {
          'color': '#2548B4',
          'fontSize': '1.6vh'
        },
        'color': '#b8c7f5',
        'className': 'r-2x'
      }
    ],
    'id': 'u:ea46385d60d4'
  };

  // 容器类组件必需字段
  // regions = [
  //   {
  //     key: 'body',
  //     label: '内容区'
  //   }
  // ];

  panelTitle = '配置'; // 右侧属性面板Title

  panelControls = [ // 右侧属性面板配置项

  ];
}

registerEditorPlugin(InfoGridPlugin)
