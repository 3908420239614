// with polyfills
import 'core-js/stable'
import 'regenerator-runtime/runtime'
// 引入自定义amis组件
import './amisComponents/index'

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import permission from './directive/permission'
import style from './directive/style'
import store from './store/'
import i18n from './locales'
import { VueAxios } from './utils/request'
import ProLayout, { PageHeaderWrapper } from '@/components/ProLayout'
import FooterToolBar from '@/components/FooterToolbar'
import FileUpload from '@/components/FileUpload'
import themePluginConfig from '../config/themePluginConfig'
import { TableSetting } from '@/components'
// 字典数据组件
import DictData from '@/components/DictData'
// 字典标签组件
import DictTag from '@/components/DictTag'

// import 'font-awesome/css/font-awesome.css'
// import 'bootstrap/dist/css/bootstrap.css'
// // import 'amis-editor/dist/style.css'
// import 'amis-editor-core/lib/style.css'
// import 'amis-theme-editor/lib/style.css'
// import 'amis-theme-editor/lib/renderers.css'
// import 'amis-ui/lib/helper.css'
// // import 'amis-ui/scss/helper.scss'
// // import 'amis-ui/scss/helper.scss'
// import 'amis-ui/lib/index.js'
// // import 'amis-ui/lib/locale/en-US'

import 'amis/lib/helper.css'
// import 'amis/sdk/iconfont.css'
// import 'amis/lib/themes/antd.css'
import 'amis/lib/themes/default.css'
// // import 'amis/lib/themes/dark.css'
// // import 'amis/lib/themes/cxd.css'
import 'amis/sdk/sdk.js'
import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/css/v4-shims.css'
import 'amis/lib/themes/antd.css'
// // import 'amis/lib/helper.css';
import 'amis/sdk/iconfont.css'
import 'amis-editor-core/lib/style.css'
// import { setDefaultTheme } from 'amis'

import bootstrap from './core/bootstrap'
import './core/lazy_use' // use lazy load components
import './permission' // permission control
import './utils/filter' // global filter
import './global.less' // global style
import '@/assets/css/main.css' // ruoyi css main.css
import { getDicts } from '@/api/system/dict/data'
import { getConfigKey } from '@/api/system/config'
import { parseTime, resetForm, addDateRange, selectDictLabel, selectDictLabels, handleTree, tableSorter } from '@/utils/ruoyi'
import { download } from '@/utils/request'

// 全局混入
import idnex from '@/mixins/index.js'

// 高德地图
import VueAMap from 'vue-amap'

// setDefaultTheme('antd')
Vue.mixin(idnex)

// 全局方法挂载
Vue.prototype.getDicts = getDicts
Vue.prototype.getConfigKey = getConfigKey
Vue.prototype.parseTime = parseTime
Vue.prototype.resetForm = resetForm
Vue.prototype.addDateRange = addDateRange
Vue.prototype.selectDictLabel = selectDictLabel
Vue.prototype.selectDictLabels = selectDictLabels
Vue.prototype.download = download
Vue.prototype.handleTree = handleTree
Vue.prototype.tableSorter = tableSorter
Vue.config.productionTip = false

// mount axios to `Vue.$http` and `this.$http`
Vue.use(VueAxios)
// use pro-layout components
Vue.component('pro-layout', ProLayout)
Vue.component('page-container', PageHeaderWrapper)
Vue.component('page-header-wrapper', PageHeaderWrapper)
Vue.component('footer-tool-bar', FooterToolBar)
Vue.component('file-upload', FileUpload)
Vue.component('table-setting', TableSetting)
Vue.component('dict-tag', DictTag)

Vue.use(permission)
Vue.use(style)
DictData.install()

Vue.use(VueAMap)

window.umi_plugin_ant_themeVar = themePluginConfig.theme
new Vue({
  router,
  store,
  i18n,
  // init localstorage, vuex
  created: bootstrap,
  render: h => h(App)
}).$mount('#app')
