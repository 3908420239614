/* eslint-disable no-template-curly-in-string */
/**
 * @file 编辑器扩展, 增加自定义组件
 */
import { BasePlugin, registerEditorPlugin } from 'amis-editor'

export class InfoGridPlugin extends BasePlugin {
  // 关联渲染器名字
  rendererName = 'com-pie-chart';
  $schema = '/schemas/UnkownSchema.json';
  notRenderFormZone = true;
  // 组件名称（组件面板显示的Title）
  name = '编辑按钮';
  order=0; // 排序
  description = '编辑按钮信息';
  tags = ['布局容器', '表格'];
  icon = 'fa fa-file-code-o';
  scaffold = { // 插入到页面时需要
    'type': 'button',
    'label': '编辑',
    'onEvent': {
      'click': {
        'actions': [
          {
            'ignoreError': false,
            'actionType': 'drawer',
            'drawer': {
              'closeOnEsc': true,
              'size': 'md',
              'type': 'drawer',
              'closeOnOutside': true,
              'title': '编辑',
              'body': [
                {
                  'type': 'form',
                  'title': '表单',
                  'body': [
                    {
                      'name': 'id',
                      'label': '设备id',
                      'id': 'UPDATE:设备id',
                      'type': 'input-text',
                      'hidden': true
                    },
                    {
                      'name': 'deviceName',
                      'label': '设备名称',
                      'id': 'UPDATE:设备名称',
                      'type': 'input-text',
                      'required': true
                    },
                    {
                      'name': 'deviceNo',
                      'label': '设备编号',
                      'id': 'UPDATE:设备编号',
                      'type': 'input-text',
                      'required': true
                    },
                    {
                      'name': 'deviceType',
                      'label': '设备类型',
                      'id': 'u:4d59514d2963',
                      'type': 'select',
                      'options': [
                        {
                          'label': '直连设备',
                          'value': 1
                        },
                        {
                          'label': '网关设备',
                          'value': 2
                        }
                      ],
                      'value': '',
                      'multiple': false,
                      'required': true
                    },
                    {
                      'name': 'sampleId',
                      'label': '所属模板',
                      'id': 'u:74f231ce5270',
                      'type': 'select',
                      'required': true,
                      'source': {
                        'url': '/gather/sample/info/getSampleDataSource?sampleType=${deviceType}',
                        'method': 'get',
                        'requestAdaptor': '',
                        'adaptor': '',
                        'messages': {
                        },
                        'dataType': 'json'
                      },
                      'multiple': false
                    },
                    {
                      'name': 'projectId',
                      'label': '所属项目',
                      'id': 'UPDATE:所属项目',
                      'type': 'tree-select',
                      'multiple': false,
                      'onEvent': {
                      },
                      'enableNodePath': false,
                      'showIcon': true,
                      'initiallyOpen': true,
                      'source': {
                        'url': '/system/project/info/projectAllTree',
                        'method': 'get'
                      },
                      'labelField': 'projectName',
                      'valueField': 'id'
                    },
                    {
                      'name': 'iccid',
                      'label': '4G卡标识',
                      'id': 'UPDATE:4G卡标识',
                      'type': 'input-text'
                    },
                    {
                      'name': 'imei',
                      'label': 'Imei',
                      'id': 'UPDATE:Imei',
                      'type': 'input-text'
                    },
                    {
                      'name': 'icon',
                      'label': '设备图标',
                      'id': 'u:496fb129f578',
                      'type': 'input-image',
                      'autoUpload': true,
                      'proxy': true,
                      'uploadType': 'fileReceptor',
                      'imageClassName': 'r w-full'
                    },
                    {
                      'name': 'status',
                      'label': '状态',
                      'id': 'UPDATE:状态(1-启用,2-禁用)',
                      'type': 'input-text',
                      'hidden': true,
                      'value': '1'
                    },
                    {
                      'name': 'remark',
                      'label': '备注',
                      'id': 'UPDATE:备注',
                      'type': 'input-text'
                    }
                  ],
                  'api': {
                    'url': '/gather/device/info/edit',
                    'method': 'post',
                    'requestAdaptor': '',
                    'adaptor': 'response.data={};\r\nreturn response;',
                    'messages': {
                      'success': '更新成功',
                      'failed': '更新失败'
                    },
                    'dataType': 'json',
                    'data': {
                      '&': '$$'
                    }
                  },
                  'id': 'u:66419b04de42',
                  'onEvent': {
                    'submitSucc': {
                      'weight': 0,
                      'actions': [
                        {
                          'componentId': 'DeviceList:table',
                          'ignoreError': false,
                          'actionType': 'reload',
                          'args': {
                            'resetPage': true
                          }
                        }
                      ]
                    }
                  },
                  'name': '设备列表'
                }
              ],
              'showCloseButton': true,
              'showErrorMsg': true,
              'showLoading': true,
              'className': 'app-popover',
              'id': 'u:0e0dd7816fef'
            }
          }
        ]
      }
    },
    'id': 'u:f5b53cc0f29a',
    'themeCss': {
      'className': {
        'background:default': 'rgba(255, 44, 44, 0.06)',
        'font:default': {
          'color': '#ff2c2c'
        },
        'border:default': {
          'border': '0'
        },
        'background:hover': 'rgba(255, 44, 44, 0.06)',
        'font:hover': {
          'color': '#ff2c2c'
        },
        'border:hover': {
          'border': '0'
        },
        'background:active': 'rgba(255, 44, 44, 0.06)',
        'font:active': {
          'color': '#ff2c2c'
        },
        'border:active': {
          'border': '0'
        }
      }
    },
    'className': 'className-f5b53cc0f29a',
    'icon': 'far fa-edit',
    'hiddenOn': "${permissions!='*:*:*'&&!ARRAYINCLUDES(permissions,'gather:device:edit')}"
  };
  previewSchema = { // 组件面板预览时需要
    'type': 'button',
    'label': '编辑',
    'onEvent': {
      'click': {
        'actions': [
          {
            'ignoreError': false,
            'actionType': 'drawer',
            'drawer': {
              'closeOnEsc': true,
              'size': 'md',
              'type': 'drawer',
              'closeOnOutside': true,
              'title': '编辑',
              'body': [
                {
                  'type': 'form',
                  'title': '表单',
                  'body': [
                    {
                      'name': 'id',
                      'label': '设备id',
                      'id': 'UPDATE:设备id',
                      'type': 'input-text',
                      'hidden': true
                    },
                    {
                      'name': 'deviceName',
                      'label': '设备名称',
                      'id': 'UPDATE:设备名称',
                      'type': 'input-text',
                      'required': true
                    },
                    {
                      'name': 'deviceNo',
                      'label': '设备编号',
                      'id': 'UPDATE:设备编号',
                      'type': 'input-text',
                      'required': true
                    },
                    {
                      'name': 'deviceType',
                      'label': '设备类型',
                      'id': 'u:4d59514d2963',
                      'type': 'select',
                      'options': [
                        {
                          'label': '直连设备',
                          'value': 1
                        },
                        {
                          'label': '网关设备',
                          'value': 2
                        }
                      ],
                      'value': '',
                      'multiple': false,
                      'required': true
                    },
                    {
                      'name': 'sampleId',
                      'label': '所属模板',
                      'id': 'u:74f231ce5270',
                      'type': 'select',
                      'required': true,
                      'source': {
                        'url': '/gather/sample/info/getSampleDataSource?sampleType=${deviceType}',
                        'method': 'get',
                        'requestAdaptor': '',
                        'adaptor': '',
                        'messages': {
                        },
                        'dataType': 'json'
                      },
                      'multiple': false
                    },
                    {
                      'name': 'projectId',
                      'label': '所属项目',
                      'id': 'UPDATE:所属项目',
                      'type': 'tree-select',
                      'multiple': false,
                      'onEvent': {
                      },
                      'enableNodePath': false,
                      'showIcon': true,
                      'initiallyOpen': true,
                      'source': {
                        'url': '/system/project/info/projectAllTree',
                        'method': 'get'
                      },
                      'labelField': 'projectName',
                      'valueField': 'id'
                    },
                    {
                      'name': 'iccid',
                      'label': '4G卡标识',
                      'id': 'UPDATE:4G卡标识',
                      'type': 'input-text'
                    },
                    {
                      'name': 'imei',
                      'label': 'Imei',
                      'id': 'UPDATE:Imei',
                      'type': 'input-text'
                    },
                    {
                      'name': 'icon',
                      'label': '设备图标',
                      'id': 'u:496fb129f578',
                      'type': 'input-image',
                      'autoUpload': true,
                      'proxy': true,
                      'uploadType': 'fileReceptor',
                      'imageClassName': 'r w-full'
                    },
                    {
                      'name': 'status',
                      'label': '状态',
                      'id': 'UPDATE:状态(1-启用,2-禁用)',
                      'type': 'input-text',
                      'hidden': true,
                      'value': '1'
                    },
                    {
                      'name': 'remark',
                      'label': '备注',
                      'id': 'UPDATE:备注',
                      'type': 'input-text'
                    }
                  ],
                  'api': {
                    'url': '/gather/device/info/edit',
                    'method': 'post',
                    'requestAdaptor': '',
                    'adaptor': 'response.data={};\r\nreturn response;',
                    'messages': {
                      'success': '更新成功',
                      'failed': '更新失败'
                    },
                    'dataType': 'json',
                    'data': {
                      '&': '$$'
                    }
                  },
                  'id': 'u:66419b04de42',
                  'onEvent': {
                    'submitSucc': {
                      'weight': 0,
                      'actions': [
                        {
                          'componentId': 'DeviceList:table',
                          'ignoreError': false,
                          'actionType': 'reload',
                          'args': {
                            'resetPage': true
                          }
                        }
                      ]
                    }
                  },
                  'name': '设备列表'
                }
              ],
              'showCloseButton': true,
              'showErrorMsg': true,
              'showLoading': true,
              'className': 'app-popover',
              'id': 'u:0e0dd7816fef'
            }
          }
        ]
      }
    },
    'id': 'u:f5b53cc0f29a',
    'themeCss': {
      'className': {
        'background:default': 'rgba(255, 44, 44, 0.06)',
        'font:default': {
          'color': '#ff2c2c'
        },
        'border:default': {
          'border': '0'
        },
        'background:hover': 'rgba(255, 44, 44, 0.06)',
        'font:hover': {
          'color': '#ff2c2c'
        },
        'border:hover': {
          'border': '0'
        },
        'background:active': 'rgba(255, 44, 44, 0.06)',
        'font:active': {
          'color': '#ff2c2c'
        },
        'border:active': {
          'border': '0'
        }
      }
    },
    'className': 'className-f5b53cc0f29a',
    'icon': 'far fa-edit',
    'hiddenOn': "${permissions!='*:*:*'&&!ARRAYINCLUDES(permissions,'gather:device:edit')}"
  };

  // 容器类组件必需字段
  // regions = [
  //   {
  //     key: 'body',
  //     label: '内容区'
  //   }
  // ];

  panelTitle = '配置'; // 右侧属性面板Title

  panelControls = [ // 右侧属性面板配置项

  ];
}

registerEditorPlugin(InfoGridPlugin)
