<template>
  <div id="userLayout" :class="['user-layout-wrapper', isMobile && 'mobile']">
    <div class="container" :class="['loginpage-'+defaultSettings.loginType]">
      <!-- 登录页顶部logo和功能区 -->
      <div class="home-top">
        <img src="@/assets/homeImg/login_logo.png" >
      </div>
      <!-- 左侧内容展示区 -->
      <div class="ad-pic" :class="defaultSettings.loginType==6?'type-6':''">
        <div>创新，创造，只为生活更美好</div>
        <div class="ad-pic-fbt">Innovation,creation,only for better life</div>
        <img v-if="defaultSettings.loginType!=6" :src="require(`@/assets/homeImg/left-ad-${defaultSettings.loginType}.png`)">
      </div>

      <!-- 右侧登录信息 -->
      <div class="login-container">
        <div class="login-weaper  animated bounceInDown">
          <a-tabs
            v-model="activeKey"
            :defaultActiveKey="activeKey"
            :size="'large'"
            :tabBarStyle="{
              'display':'flex','justify-content':'center','margin-bottom':0,'font-size':'16px',
              'font-family': 'PingFang SC-Regular, PingFang SC'
            }"
            @change="tabChange">
            <a-tab-pane key="1" tab="密码登录"></a-tab-pane>
            <a-tab-pane key="2" tab="验证码登录"></a-tab-pane>
          </a-tabs>
          <p class="login-tip">{{ defaultSettings.title }}</p>
          <router-view ref="loginRef" />
        </div>

      </div>

      <!-- 底部信息 -->
      <div class="footer">
        <span class="links">Copyright</span>
        <span class="links">2023</span>
        <span class="links">All Rights Reserved.</span>
        <span class="links">物联网后台管理系统</span>
        <span class="links">
          <a
            href="https://beian.miit.gov.cn/#/Integrated/index">  豫ICP备14022279号 </a>
        </span>

      </div>
      <!-- <div class="top">
        <div class="header">
          <a href="/">
            <img src="~@/assets/logo.png" class="logo" alt="logo">
            <span class="title">XXX后台管理系统</span>
          </a>
        </div>
        <div class="desc">

        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import { deviceMixin } from '@/store/device-mixin'
import defaultSettings from '@/config/defaultSettings'

export default {
  name: 'UserLayout',
  mixins: [deviceMixin],
  data() {
    return {
      defaultSettings,
      activeKey: '1'
    }
  },
  mounted () {
    document.body.classList.add('userLayout')
  },
  beforeDestroy () {
    document.body.classList.remove('userLayout')
  },
  methods: {
    tabChange(activeKey) {
      console.log('activeKey>>>>>>', activeKey)
      console.log(this.$refs.loginRef)
      this.$refs.loginRef.getCode(Number(activeKey) !== 1)
    }
  }
}
</script>

<style lang="less" scoped>
#userLayout.user-layout-wrapper {
    height: 100%;

    &.mobile {
      .container {
        .main {
          max-width: 368px;
          width: 98%;
        }
      }
    }

    .container {
      // width: 100%;
      min-height: 100%;
      // width: 1920px;
      width: 100vw;
      height: 100vh;
      // height: 1080px;
      background:#d9e2ef url('~@/assets/homeImg/br.png') no-repeat 100% 46px;

      // background: #f0f2f5 url(~@/assets/background.svg) no-repeat 50%;
      // background-size: 100%;
      // padding: 110px 0 144px;
      position: relative;

      &.loginpage-6 {
        background: url('~@/assets/homeImg/bg-1.png') no-repeat 100% 100%;
        background-size: 100% 100%;
      }

      a {
        text-decoration: none;
      }

      .home-top{
        display: flex;
        align-items: center;
        width: 100%;
        // padding-top: 0.92vh;
        padding-top: 2.87vh;
        // padding-left: 6.25vw;
        padding-left: 3.22vw;
        // height: 46px;
        // height: 4.26vh;
        // background: #FFFFFF;
        position: absolute;
        top: 0;
        left: 0;
        img{
          // width: 70px;
          // height: 30px;
          // width: 3.6vw;
          // height: 2.7vh;
          height: 3.52vh;
          margin: 0 30px;
        }
      }

      .ad-pic{
        position: absolute;
        // width: 500px;
        // width: 26.04vw;
        // height: 500px;
        // top: 190px;
        top: 18.28vh;
        // left: 430px;
        // left: 20.73vw;
        left: 14.84vw;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        div{
          width: 100%;
          // font-size: 56px;
          font-size: 5.1vh;
          font-family: Alibaba PuHuiTi-Bold, Alibaba PuHuiTi;
          font-weight: bold;
          color: #1C1F27;
          word-break: normal;
          overflow: hidden;
          margin-bottom: 13px;

          &.ad-pic-fbt {
            // font-size: 20px;
            font-size: 2.96vh;
            font-family: Microsoft PhagsPa-Regular, Microsoft PhagsPa;
            font-weight: 400;
            color: #1C1F27;
            line-height: 2.96vh;
            margin-bottom: 33px;

          }
        }

        img{
          width: 425px;
          height: 362px;
        }

        &.type-6 {
          // height: 100%;
          top: 33.8vh;

          div{
            color: #FFFFFF;

            &.ad-pic-fbt {
              color: #FFFFFF;

            }
          }
        }
      }

      .login-container {
        // position: relative;
        // width: 473px;
        width: 24.6vw;
        height: auto;
        // padding-bottom: 8.51vh;
        background: #FFFFFF;
        border-radius: 6px 6px 6px 6px;
        // margin-left: 1132px;
        // margin-top: 145px;
        position: absolute;
        // top: 190px;
        // left: 1130px;
        // top: 40.49vh;
        top: 18.28vh;
        left: 58.96vw;
      }
      .login-tip {
        font-size: 22px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        text-align: center;
        margin: 0;
        padding: 3.8vh 0;
      }

      // .login-border {
      //   width: 376px;
      // }

      .top {
        text-align: center;

        .header {
          height: 44px;
          line-height: 44px;

          .badge {
            position: absolute;
            display: inline-block;
            line-height: 1;
            vertical-align: middle;
            margin-left: -12px;
            margin-top: -10px;
            opacity: 0.8;
          }

          .logo {
            height: 44px;
            vertical-align: top;
            margin-right: 16px;
            border-style: none;
          }

          .title {
            font-size: 33px;
            color: rgba(0, 0, 0, .85);
            font-family: Avenir, 'Helvetica Neue', Arial, Helvetica, sans-serif;
            font-weight: 600;
            position: relative;
            top: 2px;
          }
        }
        .desc {
          font-size: 14px;
          color: rgba(0, 0, 0, 0.45);
          margin-top: 12px;
          margin-bottom: 40px;
        }
      }

      .main {
        // min-width: 260px;
        // width: 368px;
        // width: 100%;
        // margin: 0 auto;
        margin: 0 2.5vw;
      }

      .footer {
        position: absolute;
        width: 100%;
        bottom: 0;
        padding: 0 16px;
        margin: 0 0 45px;
        text-align: center;

        .links {

          font-size: 14px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #FFFFFF;
          margin-right: 10px;
          a {
            color: #FFFFFF;
            &:not(:last-child) {
              margin-right: 40px;
            }
          }
        }
      }

      @media screen and (max-width:1400px) {
        .ad-pic {
          left: 5.84vh;
        }
      }

      @media screen and (max-width:1200px) {
        .ad-pic {
          width: 100%;
          text-align: center;
          top: 16.8vh !important;
          left: 0;
        }

        .login-container {
          width: 46.6vw;
          top: 35.28vh !important;
          left: 50%;
          margin-left: -23.3vw;
        }
      }

      @media screen and (max-width:500px) {
        .ad-pic {
          width: 100%;
          text-align: center;
          top: 12.8vh !important;
          left: 0;
        }

        .login-container {
          width: 83.6vw;
          top: 37.28vh !important;
          left: auto;
          margin-left: 8.2vw;
        }
      }
    }

  }

</style>
