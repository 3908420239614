import './index.less'

import { Menu, Dropdown } from 'ant-design-vue'
// Icon
import { i18nRender } from '@/locales'
import i18nMixin from '@/store/i18n-mixin'

const locales = ['zh-CN', 'en-US']
const languageLabels = {
  'zh-CN': '简体中文',
  'en-US': 'English'
}
// eslint-disable-next-line
const languageIcons = {
  'zh-CN': '🇨🇳',
  'en-US': '🇺🇸'
}

const SelectLang = {
  props: {
    prefixCls: {
      type: String,
      default: 'ant-pro-drop-down'
    }
  },
  name: 'SelectLang',
  mixins: [i18nMixin],
  render () {
    const { prefixCls } = this
    const changeLang = ({ key }) => {
      this.setLang(key)
      window.location.reload()
    }
    const langMenu = (
      <Menu class={['menu', 'ant-pro-header-menu']} selectedKeys={[this.currentLang]} onClick={changeLang}>
        {locales.map(locale => (
          // disabled={locale !== 'zh-CN'}
          <Menu.Item key={locale} >
            <span role="img" aria-label={languageLabels[locale]}>
              {languageIcons[locale]}
            </span>{' '}
            {languageLabels[locale]}
          </Menu.Item>
        ))}
      </Menu>
    )
    const layout_lang = require('@/assets/homeImg/layout_lang.png')
    return (
      <Dropdown overlay={langMenu} placement="bottomRight">
        <span class={prefixCls} style={{ display: 'flex', alignItems: 'center', padding: 0 }}>
          {/* <Icon type="global" title={i18nRender('navBar.lang')} /> */}
          {/* <img style="width: 7px;margin-left:8px" src="@/assets/homeImg/layout_lang.png" title={i18nRender('navBar.lang')} /> */}
          <img src={layout_lang} style={{ width: '30px', height: '30px' }} title={i18nRender('navBar.lang')}/>

        </span>
      </Dropdown>
    )
  }
}

export default SelectLang
