import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      // editAmisSchema:null
      scrollY: 525,
      layoutValue: 'inline'
    }
  },
  computed: {
    ...mapGetters(['permissions', 'editAmisSchema', 'saveAmisSchema'])
  },
  mounted() {
    this.setTableScrollY()
    window.onresize = () => {
      console.log('onresize>>>>>>')

      this.setTableScrollY()
    }
  },
  methods: {
    /**
     * @desc 设置表格的高度和scroll
     * @param {  }
     * @version: 1.0.0
     */
    setTableScrollY() {
      this.$nextTick(() => {
        if (document.querySelector('.ant-table')) {
          const scrollY = document.body.clientHeight - document.querySelector('.ant-table').getBoundingClientRect().top - 179
          this.$set(this, 'scrollY', scrollY)
          console.log('页面>>>>mounted>>>>scrollY>>>>1', this.scrollY)
          console.log('页面>>>>>onresize>>>>mounted>>>>scrollY>>>>offsetTop>>>>>1', document.querySelector('.ant-table').getBoundingClientRect().top)
          document.querySelector('.ant-table').style.height = `${this.scrollY + 46}px`
        }
      })
    },
    // 打开编辑器
    handleEditMenuId(row) {
      setTimeout(() => {
        open('/amis?menuId=' + row.id + '&name=' + row.menuName)
      }, 100)
    },
    handleEdit(schema, menuId) {
      console.log('打开编辑器>>>', schema)
      this.$store.commit('SET_EDITAMISSCHEMA', schema)
      // this.editAmisSchema = JSON.parse(JSON.stringify(schema))
      console.log('打开编辑器>>>editAmisSchema>>>>', this.editAmisSchema)

      setTimeout(() => {
        open('/amis?menuId=' + menuId)
        // this.$router.push({
        //   path: '/amis',
        //   query: {
        //     menuId
        //   }
        // })
      }, 100)
      // const routeUrl = this.$router.resolve('/amis')

      // window.open(routeUrl.href, "_blank")
    }
  }
}
